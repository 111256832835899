import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

FreshBooksSyncingShopifyAppLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function FreshBooksSyncingShopifyAppLogo({ sx }) {
  // OR
  const logo = <Box component="img" src="../static/icons/logo_freshbooks_syncing_shopify_app.png" sx={{ ...sx }} />;

  return <>{logo}</>;
}
