import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { useState } from 'react';
import './invoma_assets/css/invoma.scoped.css';

import logoWhite from './invoma_assets/img/logo_white.svg';

import {
  convertLineBreaker,
  getCurrencyFormater,
  getInvomaClientInfoHtml,
  getInvomaColourfulTotalHtml,
  getInvomaNotesHtml,
  getInvomaTimeItemTableHtml,
  imgUrlToData,
} from './invoiceUtil';
// ----------------------------------------------------------------------

HourlyWorkTemp.propTypes = {
  userInvoice: PropTypes.object,
  templateRef: PropTypes.object,
  isPreview: PropTypes.bool,
};

export default function HourlyWorkTemp({ userInvoice, templateRef, isPreview }) {
  const invoice = isPreview ? userInvoice : sampleInvoice;
  console.log('Invoice Obj', invoice);

  const [logoData, setLogoData] = useState(null);

  imgUrlToData(invoice.presentation.logo, (myBase64) => {
    setLogoData(myBase64);
  });

  return (
    <div className="tm_container">
      <Card>
        <body className="tm_invoice_wrap">
          <div className="tm_invoice tm_style2 tm_type1 tm_accent_border" id="tm_download_section" ref={templateRef}>
            <div className="tm_invoice_in">
              <div className="tm_invoice_head tm_mb20 tm_m0_md">
                <div className="tm_invoice_left">
                  <div className="tm_logo">
                    <img src={logoData || logoWhite} alt="Logo" />
                  </div>
                </div>
                <div className="tm_invoice_right">
                  <div className="tm_grid_row tm_col_3">
                    <div className="tm_text_center">
                      <p className="tm_accent_color tm_mb0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 512 512"
                          fill="currentColor"
                        >
                          <path d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z" />
                        </svg>
                      </p>
                      {invoice.ownerInfo.info_email} <br />
                      {invoice.ownerInfo.name}
                    </div>
                    <div className="tm_text_center">
                      <p className="tm_accent_color tm_mb0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 512 512"
                          fill="currentColor"
                        >
                          <path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z" />
                        </svg>
                      </p>
                      {invoice.ownerInfo.bus_phone} <br />
                      Monday to Friday
                    </div>
                    <div className="tm_text_center">
                      <p className="tm_accent_color tm_mb0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 512 512"
                          fill="currentColor"
                        >
                          <circle cx="256" cy="192" r="32" />
                          <path d="M256 32c-88.22 0-160 68.65-160 153 0 40.17 18.31 93.59 54.42 158.78 29 52.34 62.55 99.67 80 123.22a31.75 31.75 0 0051.22 0c17.42-23.55 51-70.88 80-123.22C397.69 278.61 416 225.19 416 185c0-84.35-71.78-153-160-153zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z" />
                        </svg>
                      </p>
                      {invoice.ownerInfo.street} {invoice.ownerInfo.street && <br />}
                      {invoice.ownerInfo.street2} {invoice.ownerInfo.street2 && <br />}
                      {invoice.ownerInfo.country} {invoice.ownerInfo.code} {invoice.ownerInfo.country && <br />}
                    </div>
                  </div>
                </div>
                <div className="tm_shape_bg tm_accent_bg" />
              </div>
              <div className="tm_invoice_info tm_mb10">
                <div className="tm_invoice_info_left">
                  <p className="tm_mb2">
                    <b>Invoice To:</b>
                  </p>
                  <p>{getInvomaClientInfoHtml(invoice)}</p>
                </div>
                <div className="tm_invoice_info_right">
                  <div className="tm_f50 tm_text_uppercase tm_text_center tm_invoice_title tm_mb15 tm_ternary_color tm_mobile_hide">
                    Hourly Invoice
                  </div>
                  <div className="tm_grid_row tm_col_3 tm_invoice_info_in tm_round_border">
                    <div>
                      <span>Invoice No:</span> <br />
                      <b className="tm_primary_color">{invoice.invoiceNumber}</b>
                    </div>
                    <div>
                      <span>Invoice Date:</span> <br />
                      <b className="tm_primary_color">{invoice.issueDate}</b>
                    </div>
                    <div>
                      <span>Grand Total:</span> <br />
                      <b className="tm_primary_color">{getCurrencyFormater(invoice).format(invoice.totalAmount)}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tm_table tm_style1">
                <div className="tm_round_border">
                  <div className="tm_table_responsive">
                    <table>
                      <thead>
                        <tr>
                          <th className="tm_width_7 tm_semi_bold tm_primary_color">Description</th>
                          <th className="tm_width_2 tm_semi_bold tm_primary_color">Rate</th>
                          <th className="tm_width_1 tm_semi_bold tm_primary_color">Hours</th>
                          <th className="tm_width_2 tm_semi_bold tm_primary_color tm_text_right">Total</th>
                        </tr>
                      </thead>
                      {getInvomaTimeItemTableHtml(invoice)}
                    </table>
                  </div>
                </div>
                <div className="tm_invoice_footer tm_mb15 tm_m0_md">
                  <div className="tm_left_footer">
                    <div className="tm_mb10 tm_m0_md" />
                    <p className="tm_mb0">
                      <b className="tm_primary_color">Notes:</b> <br />
                      {getInvomaNotesHtml(invoice)}
                    </p>
                  </div>
                  <div className="tm_right_footer">{getInvomaColourfulTotalHtml(invoice)}</div>
                </div>
              </div>
              <div className="tm_bottom_invoice">
                <div className="tm_bottom_invoice_left">
                  <p className="tm_m0 tm_f18 tm_accent_color tm_mb5">Thank you for your business.</p>
                  <p className="tm_primary_color tm_f12 tm_m0 tm_bold">Terms & Conditions</p>
                  <p className="tm_m0 tm_f12">
                    <ul className="tm_m0 tm_note_list">{convertLineBreaker(invoice.terms)}</ul>
                  </p>
                </div>
                <div className="tm_bottom_invoice_right tm_mobile_hide">
                  <div className="tm_logo">
                    <img src={logoData || logoWhite} alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>
      </Card>
    </div>
  );
}

const sampleInvoice = {
  id: 377322,
  invoiceNumber: '#LL93784',
  clientName: 'Lowell H. Dominguez',
  description: '',
  issueDate: '2023-04-28',
  dueDate: '2023-05-28',
  totalAmount: '1738.4',
  fbStatus: 'draft',
  fbPaymentStatus: 'unpaid',
  currency: 'CAD',
  lineItems: [
    {
      amount: {
        amount: '250.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Six web page designs and three times revision	.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 9,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Website Design',
      qty: '5',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 1,
      taxAmount1: '8',
      taxAmount2: '0',
      taxName1: 'GST',
      taxName2: null,
      taxNumber1: null,
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '50.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '600.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Convert pixel-perfect frontend and make it dynamic.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 10,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Web Development',
      qty: '10',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 2,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '60.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '400.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: '"Android & Ios Application Development."',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 11,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'App Development',
      qty: '10',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 3,
      taxAmount1: '13',
      taxAmount2: '0',
      taxName1: 'HST',
      taxName2: null,
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '40.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '240.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Facebook, Youtube and Google Marketing.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 12,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Digital Marketing',
      qty: '3',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 4,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '80.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
  ],
  ownerInfo: {
    bus_phone: '6479666000',
    city: 'Toronto',
    code: 'K0B1G5',
    country: 'Canada',
    currency_code: 'CAD',
    date: '2021-03-18 16:23:38',
    fax: '',
    info_email: 'owner.demo@gmail.com',
    mob_phone: '6479666570',
    modern_system: true,
    name: 'Gelatech',
    province: 'Ontario',
    street: '86 Paul Street',
    street2: null,
    subdomain: '',
    vat_name: null,
    vat_number: null,
    vis_state: 0,
  },
  ownerContact: {
    email: 'owner@gmail.com',
    fname: 'James',
    lname: 'Chen',
    organization: '',
    userid: 1,
  },
  clientInfo: {
    street: '84 Spilman Street',
    street2: '',
    orgName: 'Victor SaaS',
    province: 'Ontario',
    country: 'Canada',
  },
  clientContacts: [
    {
      contactid: -1,
      email: 'client.demo@gmail.com',
      fname: 'Rose',
      invoiceid: 377322,
      lname: 'Luke',
      userid: 278320,
    },
  ],
  terms: 'All claims relating to quantity or shipping errors.\nDelivery dates are not guaranteed and Seller.',
  notes: 'PayPal: client.demo@gmail.com\nAccount Number: 982182',
  presentation: {
    logo: 'https://api.freshbooks.com/uploads/images/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50Ijo2NDM3MTEzLCJvcmlnaW5hbF9maWxlbmFtZSI6Imljb24tZGFyay5wbmciLCJsZW5ndGgiOjUyOTI0LCJmaWxlbmFtZSI6InVwbG9hZC1jZjUwMzcxOWUzNzhkNjIwZjUwNGIwZGFkMDIzNjcxOWY3ZmVjMTI1IiwiYnVja2V0IjoidXBsb2FkcyIsImtleSI6Iidkb2NzLSctNjQzNzExMy91cGxvYWQtY2Y1MDM3MTllMzc4ZDYyMGY1MDRiMGRhZDAyMzY3MTlmN2ZlYzEyNSIsInV1aWQiOiIyMzMxODIyZS0yNjA5LTQyZmItYTNkYy1iZjQ1YmQ3OGE3Y2YifQ.IFiS4ROkwibdxyoiGwSMZ7O2wzsBJnMd5r-VGqUGk00',
    dateFormat: 'mm/dd/yyyy',
  },
};
