import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Typography, Stack } from '@mui/material';
import TogglSyncingLogo from '../../../components/TogglSyncingLogo';
import FBLogo from '../../../components/FBLogo';

// ----------------------------------------------------------------------

LoginForm.propTypes = {
  pageTitle: PropTypes.string,
  fbAppSetting: PropTypes.shape({
    app_client_id: PropTypes.string,
    app_client_secret: PropTypes.string,
    app_redirect_url: PropTypes.string,
    app_auth_page: PropTypes.string,
  }),
};

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  // margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0),
}));

export default function LoginForm({ fbAppSetting, pageTitle }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onClick = async () => {
    // const authURL = envVariable.toggl_fb_app_auth_page;
    // navigate(authURL, { replace: true });
    // setFBAppSetting(fbAppSetting);
    console.log('LoginForm', `set redirect to ${fbAppSetting.app_redirect_url}`);
    window.location.replace(fbAppSetting.app_auth_page);
    // window.open(fbAppSetting.app_auth_page);
  };

  return (
    <ContentStyle>
      <Typography variant="h4" gutterBottom>
        {pageTitle || 'Sign in to One Tracking'}
      </Typography>

      <Typography sx={{ color: 'text.secondary', mb: 5 }}>
        Click below to sign in with your FreshBooks account.
      </Typography>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} onClick={onClick}>
        <Stack spacing={4} direction="row" alignItems="center" justifyContent="center">
          <FBLogo sx={{ marginRight: 1 }} />
          Login with FreshBooks
        </Stack>
      </LoadingButton>

      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        Don’t have an FreshBooks account?{' '}
        <Link variant="subtitle2" href="http://fbuy.me/utChW">
          Try It Free
        </Link>
      </Typography>
    </ContentStyle>
  );
}
