import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useAuth, { FBAppName } from '../hooks/useAuth';

const LoginAuthGuard = ({ children }) => {
  const { identity } = useAuth();

  const currentApp = localStorage.getItem('currentApp');
  if (identity) {
    switch (currentApp) {
      case FBAppName.invoice_in_style:
        return <Navigate to="/dashboard/invoiceinstyle" />;
      case FBAppName.toggl_sycing:
        return <Navigate to="/dashboard/timetracking" />;
      case FBAppName.shopify_syncing:
        return <Navigate to="/dashboard/otherincome" />;
      default:
        return <Navigate to="/dashboard/timetracking" />;
    }
  }

  return <>{children}</>;
};

LoginAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default LoginAuthGuard;
