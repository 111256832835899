import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 1000,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {
  return (
    <Page title="Privacy Policy (Shopify FreshBooks Syncing App)" sx={{ width: '100%' }}>
      <Container>
        <ContentStyle sx={{ textAlign: 'left', alignItems: 'left' }}>
          <Typography variant="h3" sx={{ marginBottom: 1 }}>
            Privacy Policy Terms
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            This Privacy Policy explains how the Freshbooks Integration app ("the App") collects, uses, and shares
            information about you and your customers in connection with your use of the App. By using the App, you
            consent to the collection, use, and sharing of information as described in this Privacy Policy.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Collection of Information
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The App collects certain information about you and your customers in order to provide its services. This
            information includes: Shopify store data: The App collects your Shopify store data, such as your orders and
            customer information, in order to sync them with Freshbooks. Freshbooks account data: The App collects your
            Freshbooks account data, such as your API key and login credentials, in order to authenticate and connect to
            your Freshbooks account.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Use of Information
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The App uses the collected information in order to provide its services. Specifically, the App uses the
            information to: Sync your Shopify orders and customer information with Freshbooks. Customize your accounting
            settings in Freshbooks to match your business needs. Reduce the risk of errors and save time on manual data
            entry.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Sharing of Information
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The App does not sell or rent your information to third parties. However, the App may share your information
            with third-party service providers who assist us in providing the App's services. For example, we may share
            your information with Freshbooks in order to facilitate the integration between your Shopify store and your
            Freshbooks account.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Data Retention
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The App retains the collected information for as long as necessary to provide its services. When you
            uninstall the App, we will delete all data associated with your use of the App.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Data Retention
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            The App retains the collected information for as long as necessary to provide its services. When you
            uninstall the App, we will delete all data associated with your use of the App.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Your Rights
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            You have certain rights with respect to the collected information, including the right to access, correct,
            and delete the information. Please contact us at [insert contact information] to exercise your rights.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Changes to this Privacy Policy
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            We may update this Privacy Policy from time to time in order to reflect changes to our information
            practices. If we make any material changes to the Privacy Policy, we will notify you by posting the updated
            Privacy Policy on our website and by notifying you via email or through the App.
          </Typography>

          <Typography variant="h5" sx={{ marginBottom: 1 }}>
            Contact Us
          </Typography>

          <Typography variant="body" sx={{ marginBottom: 3 }}>
            If you have any questions or concerns about this Privacy Policy, please contact us at
            hellogelatech@gmail.com.
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink} sx={{ marginTop: 3 }}>
            Go back to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
