import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCL6wJ0dvHv2p2pfVLet6J_nJ-wmR6lck8',
  authDomain: 'onetracking-c5931.firebaseapp.com',
  projectId: 'onetracking-c5931',
  storageBucket: 'onetracking-c5931.appspot.com',
  messagingSenderId: '73618922446',
  appId: '1:73618922446:web:4d5fa1fcf38ee7081c299f',
  measurementId: 'G-YLHS8ZM2BQ',
};

const useFirebase = () => {
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebaseApp);
  // Initialize Cloud Firestore and get a reference to the service
  const firestoreDB = getFirestore(firebaseApp);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location.pathname]);

  return { firebaseApp, analytics, firestoreDB };
};

export default useFirebase;
