import _, { filter, map, set } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Confetti from 'react-confetti';
// material
import {
  Menu,
  Link,
  IconButton,
  Alert,
  Paper,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Select,
  Snackbar,
  InputLabel,
  FormControl,
  MenuItem,
  Checkbox,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  Container,
  Typography,
  Grid,
  TableContainer,
  TablePagination,
  CircularProgress,
  Autocomplete,
  ButtonGroup,
  Box,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { height } from '@mui/system';
import ProjectSyncMatch from '../components/ProjectSyncMatch';
import Emoji from '../components/Emoji';
import SyncStatusLabel from '../components/SyncStatusLabel';
import useAuth, { PlanName } from '../hooks/useAuth';
import useSync, { autoSyncStatus, statusCopyMap } from '../hooks/useSync';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { AppWidgetSummary } from '../sections/@dashboard/app';
// mock
// import timeTrackingList from '../_mock/timetracking';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'project', label: 'Project / Client', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'startedAt', label: 'Started At', alignRight: false },
  { id: 'status', label: 'Status', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'startedAt') {
    if (moment(b[orderBy]).isBefore(a[orderBy])) return -1;
    if (moment(b[orderBy]).isAfter(a[orderBy])) return 1;
    if (moment(b[orderBy]).isSame(a[orderBy])) return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, statusFilter) {
  if (!array || array.length === 0) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  let filteredList = array;
  if (query) {
    filteredList = filter(
      filteredList,
      (record) =>
        record.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (!!record.project && record.project.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (!!record.client && record.client.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  if (statusFilter && statusFilter !== statusCopyMap.all) {
    filteredList = filter(filteredList, (record) => record.status === statusFilter);
  }
  if (query || statusFilter) return filteredList;
  return stabilizedThis.map((el) => el[0]);
}

export default function TimeTracking() {
  const {
    identity,
    user,
    saveTogglAPIToken,
    saveTogglUserID,
    getUser,
    saveTogglSyncToProjectID,
    getTogglSyncToProjectID,
    saveTogglSyncToClientID,
    saveTogglSyncedDate,
    saveFBBusinessID,
    saveTogglSyncToOneMode,
    getFBBusinessID,
    getTogglSyncedDate,
    isPlan,
    saveTogglAutoSyncMap,
    getUserPreference,
    saveUserPreference,
  } = useAuth();

  const [togglAPIToken, setTogglAPIToken] = useState();

  const [tokenValue, setTokenValue] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const [filteredRecords, setFilteredRecords] = useState([]);

  const {
    timeTrackingList,
    syncSelectedTimeTrackingRecordsToFB,
    fetchTimeTracking,
    fetchFBProjects,
    fetchFBClients,
    createFBProjectForToggl,
    createFBClientForToggl,
    deleteSelectedRecords,
    fetchTogglProjects,
    setupTogglWebhook,
    joinTogglBeta,
  } = useSync();

  const [fbProjects, setFBProjects] = useState([]);
  const [togglProjects, setTogglProjects] = useState([]);
  const [projectID, setProjectID] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [serviceID, setServiceID] = useState(null);

  const [fbClients, setFBClients] = useState([]);
  const [clientID, setClientID] = useState(0);

  const [fbBusinesses, setFBBusinesses] = useState([]);
  const [businessID, setBusinessID] = useState(0);
  const [accountBusinessID, setAccountBusinessID] = useState(0);

  const [syncedDate, setSyncedDate] = useState();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('startedAt');

  const [filterName, setFilterName] = useState('');
  const [filterByStatus, setFilterByStatus] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [syncToOneMode, setSyncToOneMode] = useState(false);
  const [isPlusUser, setIsPlusUser] = useState(false);
  const [autoSyncProjectMap, setAutoSyncProjectMap] = useState(null);
  const [isAddingAutoSyncMatch, setIsAddingAutoSyncMatch] = useState(false);

  const oldestSinceDate = moment().subtract(3, 'months').add(2, 'days').format('YYYY-MM-DD');

  useEffect(() => {
    async function fetchUserObjFromDB() {
      const userObj = await getUser();
      console.log(userObj.data.toggl_apitoken);
      setTogglAPIToken(userObj.data.toggl_apitoken);
      reloadTimeTrackingTable(userObj.data.toggl_apitoken);
      setProjectID(userObj.data.toggl_syncto_projectid || 0);
      setClientID(userObj.data.toggl_syncto_clientid || 0);
      setFilterByStatus(userObj.data.toggl_filter_by_status || statusCopyMap.all);
      setAutoSyncProjectMap(userObj.data.toggl_autosync_project_map || null);

      const businessArray = identity.business_memberships?.map((business) => ({
        id: business.business.id,
        account_id: business.business.account_id,
        name: business.business.name,
        role: business.role,
        status: business.business.status,
      }));
      setFBBusinesses(businessArray);
      console.log('businessArray', businessArray);
      const tempBusinessID = userObj.data.fb_business_id || businessArray[0]?.id;
      setBusinessID(tempBusinessID);
      console.log('businessID', tempBusinessID);
      const tempAccountBusinessID = userObj.data.fb_account_business_id || businessArray[0]?.account_id;
      setAccountBusinessID(tempAccountBusinessID);

      setSyncToOneMode(userObj.data.toggl_syncto_onemode || false);
      console.log('check before open', togglAPIToken);
      if (!userObj.data.toggl_apitoken || !userObj.data.fb_business_id || !userObj.data.fb_account_business_id) {
        saveFBBusinessID(tempBusinessID, tempAccountBusinessID);
        handleTogglSettingDialogOpen();
      }

      const plusUserFlag = await isPlan(PlanName.plus);
      setIsPlusUser(plusUserFlag);

      setTogglProjects(await fetchTogglProjects(userObj.data.toggl_apitoken));
    }
    if (businessID === 0) fetchUserObjFromDB();
  });

  useEffect(() => {
    async function fetchFBProjectsAndClients() {
      const freshbooksProjects = await fetchFBProjects(businessID);
      const userObj = await getUser();
      console.log('freshbooksProjects', freshbooksProjects);
      setFBProjects(freshbooksProjects);
      setProjectID(userObj.data.toggl_syncto_projectid || freshbooksProjects[0]?.id);
      setSelectedProject(_.find(freshbooksProjects, (project) => project.id === userObj.data.toggl_syncto_projectid));

      const freshbooksClients = await fetchFBClients(accountBusinessID);
      console.log('freshbooksClients', freshbooksClients);
      setFBClients(freshbooksClients);
      setClientID(userObj.data.toggl_syncto_clientid);
    }
    fetchFBProjectsAndClients();
  }, [businessID]);

  useEffect(() => {
    console.log('timeTrackingList', timeTrackingList);
    setFilteredRecords(applySortFilter(timeTrackingList, getComparator(order, orderBy), filterName, filterByStatus));
  }, [timeTrackingList]);

  useEffect(() => {
    console.log('businessID', businessID);
    console.log('fbBusinesses', fbBusinesses);
  });

  const getStatusNum = (ttlist, status) => {
    if (!ttlist || ttlist.length === 0) return 0;
    let totalNum = 0;
    ttlist.forEach((tt) => {
      if (tt.status === status) totalNum += 1;
    });
    return totalNum;
  };

  const handleDatePickerChange = (newValue) => {
    console.log(newValue);
    setSyncedDate(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSyncToOne = (event) => {
    setSyncToOneMode(event.target.checked);
  };

  const handleProjectChange = (event, newProject) => {
    console.log(newProject);
    setProjectID(newProject.id);
    setSelectedProject(newProject);
    setClientID(newProject.client_id);
  };

  const handleBusinessChange = (event) => {
    const businesIDToFind = event.target.value;
    const foundBusinessObj = _.find(fbBusinesses, (businessObj) => businessObj.id === businesIDToFind);
    setBusinessID(foundBusinessObj.id);
    setAccountBusinessID(foundBusinessObj.account_id);
  };

  const handleServiceChange = (event) => {
    console.log('selected service ID', event.target.value);
    setServiceID(event.target.value);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(filteredRecords);
      return;
    }
    setSelected([]);
  };

  const handleSyncAll = (event) => {
    syncSelectedTimeTrackingRecordsToFB(timeTrackingList).then(() => {
      showSnackMsg(`All records sync successfully!`);
      setSelected([]);
    });
  };

  const handleSyncSelected = (event) => {
    if (syncToOneMode)
      syncSelectedTimeTrackingRecordsToFB(selected).then(() => {
        showSnackMsg(`${selected.length} record(s) sync success!`);
        setSelected([]);
      });
    else {
      setSyncDialogOpen(true);
    }
  };

  const handleDeleteSelected = (event) => {
    deleteSelectedRecords(selected).then(() => {
      setSelected([]);
      reloadTimeTrackingTable(togglAPIToken);
    });
    setDeleteDialogOpen(false);
  };

  const handleRowSelect = (record) => {
    console.log('Selected record', record);
    const isSelected = selected.indexOf(record) === -1;
    let newSelected = _.clone(selected);
    if (isSelected) newSelected.push(record);
    else {
      newSelected = _.reject(newSelected, (tt) => tt.id === record.id);
    }
    setSelected(newSelected);
  };

  const isAllNewRecord = (selectedRecord) =>
    _.every(
      selectedRecord,
      (record) => record.status === statusCopyMap.ready_to_sync || record.status === statusCopyMap.error
    );

  const isAllSyncedRecord = (selectedRecord) =>
    _.every(selectedRecord, (record) => record.status === statusCopyMap.synced);

  const reloadTimeTrackingTable = (apiToken) => {
    console.log('reloadTimeTrackingTable');
    setIsLoading(true);
    fetchTimeTracking(apiToken).then(() => {
      setIsLoading(false);
      showSnackMsg('Refresh success!');
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClearFilters = () => {
    setFilterName('');
    setFilterByStatus(statusCopyMap.all);
    saveUserPreference('toggl_filter_by_status', statusCopyMap.all);
    setFilteredRecords(applySortFilter(timeTrackingList, getComparator(order, orderBy), '', statusCopyMap.all));
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    const filteredList = applySortFilter(
      timeTrackingList,
      getComparator(order, orderBy),
      event.target.value,
      filterByStatus
    );
    console.log('handleFilterByName', filteredList);
    setFilteredRecords(filteredList);
  };

  const handleFilterByStatusChange = (event) => {
    console.log('handleFilterByStatusChange', event.target.value);
    setFilterByStatus(event.target.value);
    saveUserPreference('toggl_filter_by_status', event.target.value);
    const filteredList = applySortFilter(
      timeTrackingList,
      getComparator(order, orderBy),
      filterName,
      event.target.value
    );
    console.log('handleFilterByStatusChange', filteredList);
    setFilteredRecords(filteredList);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [syncDialogOpen, setSyncDialogOpen] = useState(false);
  const [autoSyncDialogOpen, setAutoSyncDialogOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTogglSettingDialogOpen = (event) => {
    console.log(togglAPIToken);
    if (tokenValue !== togglAPIToken) setTokenValue(togglAPIToken);
    setDialogOpen(true);
  };

  const handleAutoSyncDialogOpen = (event) => {
    setAutoSyncDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    setDialogOpen(false);
  };

  const handleDeleteDialogClose = (event) => {
    setDeleteDialogOpen(false);
  };

  const handleRefresh = (event) => {
    reloadTimeTrackingTable(togglAPIToken);
  };

  const handleDialogConfirm = useCallback(async () => {
    if (syncToOneMode) {
      if (projectID === 0) {
        return;
      }
      saveTogglSyncToProjectID(projectID);
      saveTogglSyncToClientID(clientID);
    }

    setTogglAPIToken(tokenValue);
    saveTogglAPIToken(tokenValue);
    saveTogglUserID(tokenValue);
    saveFBBusinessID(businessID, accountBusinessID);
    // saveTogglSyncedDate(syncedDate.toString());
    saveTogglSyncToOneMode(syncToOneMode);

    // fetch again after close the dialog
    reloadTimeTrackingTable(tokenValue);
    setDialogOpen(false);
  });

  const handleSyncDialogConfirm = useCallback(async () => {
    await saveTogglSyncToProjectID(projectID);
    await saveTogglSyncToClientID(clientID);

    syncSelectedTimeTrackingRecordsToFB(selected, serviceID).then(() => {
      showSnackMsg(`${selected.length} record(s) sync success!`);
      setSelected([]);
    });

    setSyncDialogOpen(false);
  });

  const handleAutoSyncMatchSave = (newAutoSyncMatch) => {
    console.log('newAutoSyncMatch', newAutoSyncMatch);
    console.log('autoSyncProjectMap', autoSyncProjectMap);
    const newAutoSyncProjectMap = { ...autoSyncProjectMap, ...newAutoSyncMatch };
    console.log('newAutoSyncProjectMap', newAutoSyncProjectMap);
    setupTogglWebhook(Object.values(newAutoSyncMatch)[0]?.source_workspace_id);
    setAutoSyncProjectMap(newAutoSyncProjectMap);
    saveTogglAutoSyncMap(newAutoSyncProjectMap);
    setIsAddingAutoSyncMatch(false);
    showSnackMsg(`New auto sync match is saved successfully!`);
  };

  const handleAutoSyncMatchDelete = (autoSyncMatch) => {
    if (autoSyncMatch.status === autoSyncStatus.draft) {
      setIsAddingAutoSyncMatch(false);
      return;
    }
    console.log('handleAutoSyncMatchDelete old autoSyncProjectMap', autoSyncProjectMap);
    console.log('handleAutoSyncMatchDelete autoSyncMatch', autoSyncMatch);
    const newAutoSyncProjectMap = _.omit(autoSyncProjectMap, [autoSyncMatch.source_project_id]);
    console.log('handleAutoSyncMatchDelete newAutoSyncProjectMap', newAutoSyncProjectMap);
    setAutoSyncProjectMap(newAutoSyncProjectMap);
    saveTogglAutoSyncMap(newAutoSyncProjectMap);
    showSnackMsg(`Auto sync match is deleted!`);
  };

  const showSnackMsg = (msg) => {
    setSnackBarOpen(true);
    setSnackBarMsg(msg);
  };
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - timeTrackingList.length) : 0;

  const isRecordNotFound = (filterName !== '' || filterByStatus !== statusCopyMap.all) && filteredRecords.length === 0;

  return (
    <Page title="Time Tracking">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Stack direction="column" alignItems="left">
            <Typography variant="h4" gutterBottom>
              Hi {identity?.first_name}, Welcome back
            </Typography>
            <Typography variant="subtitle" gutterBottom>
              Syncing Toggl time tracking to FreshBooks
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
            <Button variant="contained" color="secondary" onClick={handleAutoSyncDialogOpen}>
              {'⚡ Auto Sync'}
            </Button>

            <Button
              variant="outlined"
              onClick={handleTogglSettingDialogOpen}
              startIcon={<Avatar alt="Toggl icon" src="./static/icons/toggl.png" sx={{ height: 20, width: 20 }} />}
            >
              Toggl Settings
            </Button>
            {syncToOneMode && (
              <Button variant="contained" startIcon={<Iconify icon="eva:sync-fill" />} onClick={handleSyncAll}>
                Sync All
              </Button>
            )}
          </Stack>
        </Stack>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Ready to Sync"
              total={getStatusNum(timeTrackingList, statusCopyMap.ready_to_sync)}
              color="info"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Synced to FreshBooks"
              total={getStatusNum(timeTrackingList, statusCopyMap.synced)}
              color="success"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Sync Error"
              total={getStatusNum(timeTrackingList, statusCopyMap.error)}
              color="error"
            />
          </Grid>
        </Grid>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            filterPlaceholder={'Search Project, Client or Description...'}
            onFilterName={handleFilterByName}
            filterByStatus={filterByStatus}
            onStatusFilterChange={handleFilterByStatusChange}
            onRefresh={handleRefresh}
            onSyncSelected={isAllNewRecord(selected) ? handleSyncSelected : null}
            onDeleteSelected={
              isAllSyncedRecord(selected)
                ? () => {
                    setDeleteDialogOpen(true);
                  }
                : null
            }
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredRecords.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredRecords.length !== 0 &&
                    filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => {
                      const { id, description, duration, status, project, client, avatarUrl, startedAt, isAutoSync } =
                        record;
                      const isItemSelected = selected.indexOf(record) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={() => handleRowSelect(record)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={() => handleRowSelect(record)} />
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ maxWidth: 300 }}>
                            <Stack direction="column" alignItems="left" spacing={0}>
                              <Typography variant="subtitle2" noWrap>
                                {project || '<No Project>'}
                              </Typography>

                              <Typography variant="caption" noWrap>
                                {client || '<No Client>'}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left" sx={{ maxWidth: 350 }}>
                            {description || '<Not Available>'}
                          </TableCell>
                          <TableCell align="left" sx={{ minWidth: 128 }}>
                            {moment.duration(duration, 'seconds').humanize()}
                          </TableCell>
                          <TableCell align="left" sx={{ minWidth: 128 }}>
                            {moment(startedAt).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell align="right" sx={{ minWidth: 128 }}>
                            <SyncStatusLabel status={status} isAutoSync={isAutoSync} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && !isLoading && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {togglAPIToken && isRecordNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {filterByStatus === statusCopyMap.ready_to_sync && filterName?.length === 0 ? (
                          <>
                            <Confetti numberOfPieces={300} />
                            <Typography variant="h4" sx={{ marginY: 8 }}>
                              Wow! You catched up everything!
                            </Typography>
                          </>
                        ) : (
                          <SearchNotFound
                            searchQuery={filterName}
                            filterStatus={filterByStatus}
                            onClearFilters={handleClearFilters}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {togglAPIToken && isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {!isLoading && timeTrackingList.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            {!togglAPIToken
                              ? "You haven't setup the Toggl API token yet!"
                              : 'No Records? Try to update the sync API token or sync since date (3 months from now)'}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={handleTogglSettingDialogOpen}
                            startIcon={
                              <Avatar alt="Toggl icon" src="./static/icons/toggl.png" sx={{ height: 20, width: 20 }} />
                            }
                          >
                            {!togglAPIToken ? 'Add Toggl API Token' : 'Update Toggl Setting'}
                          </Button>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1.5,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleTogglSettingDialogOpen}>
              <Avatar alt="Toggl icon" src="./static/icons/toggl.png" /> Toggl
            </MenuItem>
            <MenuItem>
              <Avatar alt="Harvest icon" src="./static/icons/harvest.png" /> Harvest
            </MenuItem>
          </Menu>
        </Card>

        {/* Auto Sync project match dialog */}
        <Dialog
          open={autoSyncDialogOpen && isPlusUser}
          onClose={() => {
            setAutoSyncDialogOpen(false);
          }}
          fullWidth
          sx={{}}
          maxWidth="md"
          // onClose={() => setSyncDialogOpen(false)}
          aria-labelledby="auto sync dialog"
        >
          <DialogTitle id="alert-dialog-title">Setup Auto Sync Project Match</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ pb: 2 }}>
              We will auto sync newly created Toggl time tracking to FreshBooks based on the match below.
              <Link sx={{ ml: 1 }} variant="subtitle2" target="_blank" href="https://youtu.be/rFLVw4i3c70">
                Demo Video
              </Link>
            </DialogContentText>

            {
              // object will auto order prop if key is number, I need to render the list by create_date
              autoSyncProjectMap &&
                _.sortBy(Object.values(autoSyncProjectMap), ['created_date']).map((projectMapping, index) => (
                  <ProjectSyncMatch
                    key={projectMapping.source_project_id}
                    projects={togglProjects}
                    fbProjects={fbProjects}
                    autoSyncMatch={projectMapping}
                    onSave={handleAutoSyncMatchSave}
                    onDelete={handleAutoSyncMatchDelete}
                  />
                ))
            }
            {isAddingAutoSyncMatch ? (
              <ProjectSyncMatch
                expanded
                projects={
                  autoSyncProjectMap
                    ? _.filter(
                        togglProjects,
                        (project) => _.indexOf(Object.keys(autoSyncProjectMap), project.id.toString()) < 0
                      )
                    : togglProjects
                }
                fbProjects={fbProjects}
                onSave={handleAutoSyncMatchSave}
                onDelete={handleAutoSyncMatchDelete}
              />
            ) : (
              (!autoSyncProjectMap ||
                _.filter(
                  togglProjects,
                  (project) => _.indexOf(Object.keys(autoSyncProjectMap), project.id.toString()) < 0
                ).length !== 0) && (
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    setIsAddingAutoSyncMatch(true);
                  }}
                >
                  ⚡Add an Auto Sync Match
                </Button>
              )
            )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setIsAddingAutoSyncMatch(false);
                setAutoSyncDialogOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Auto Sync Promo dialog */}
        <Dialog
          open={autoSyncDialogOpen && !isPlusUser}
          onClose={() => {
            setAutoSyncDialogOpen(false);
          }}
          fullWidth
          sx={{}}
          maxWidth="sm"
          // onClose={() => setSyncDialogOpen(false)}
          aria-labelledby="auto sync dialog"
        >
          <Stack direction="column" alignItems="center" my={3} px={5} spacing={2}>
            <Typography variant="h4">Join Auto Sync Beta</Typography>
            <Typography variant="subtitle">Never need to think about sync anymore!</Typography>
            <Box sx={{ maxWidth: '500px' }}>
              <img alt="landing" src="./static/illustrations/illustration_toggl_autosync.png" />
            </Box>
            <Stack direction="column" sx={{ width: '100%' }} alignItems="center" spacing={2}>
              <Button
                sx={{ px: 6 }}
                onClick={() => {
                  joinTogglBeta();
                  setIsPlusUser(true);
                }}
                variant="contained"
                size="large"
              >
                Join Beta
              </Button>
              <Button
                onClick={() => {
                  setAutoSyncDialogOpen(false);
                }}
              >
                Later
              </Button>
            </Stack>
          </Stack>
        </Dialog>

        {/* Select and Sync dialog */}
        <Dialog
          open={syncDialogOpen}
          onClose={() => setSyncDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Sync ${selected.length} record(s)?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Select which FreshBooks project you want sync to
            </DialogContentText>
          </DialogContent>
          <Stack direction="column" sx={{ mb: 3, mx: 3 }} spacing={3}>
            <Autocomplete
              disableClearable
              id="project-select"
              options={fbProjects}
              value={_.find(fbProjects, (project) => project.id === projectID)}
              getOptionLabel={(option) => option.title}
              onChange={handleProjectChange}
              renderInput={(params) => <TextField variant="filled" {...params} label="Freshbooks Project" />}
            />

            {selectedProject && selectedProject.services.length > 0 && (
              <FormControl variant="filled">
                <InputLabel id="service-select-label">Service of this project (Optional)</InputLabel>
                <Select labelId="service-select-label" id="service-select" onChange={handleServiceChange}>
                  {selectedProject?.services.map((service, index) => (
                    <MenuItem value={service.id} key={index}>
                      {service.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
          <DialogActions>
            <Button onClick={() => setSyncDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSyncDialogConfirm}>
              Sync
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Delete ${selected.length} sync record(s)?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              When you delete, we will only remove the sync record on OneTracking. We won't delete record on FreshBooks
              and Toggl!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>Close</Button>
            <Button onClick={handleDeleteSelected} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Setting dialog */}
        <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md">
          <DialogTitle>{!togglAPIToken ? 'Sync with Toggl' : 'Update Toggl Sync Settings'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!togglAPIToken ? 'To sync to Toggl, you need to input your Toggl API token.' : ''}
              <Stack direction="column" justifyContent="start" sx={{ mb: 2 }}>
                {/* <Typography variant="subtitle2">How do I find my Toggl API Token?</Typography> */}

                <Typography variant="subtitle2">
                  All users can locate their API key at the bottom of the
                  <Link sx={{ ml: 0.5 }} variant="subtitle2" target="_blank" href="https://track.toggl.com/profile">
                    Toggl Profile Page.
                  </Link>
                </Typography>

                <Typography variant="subtitle2">
                  Still have problem to find it? check out
                  <Link sx={{ ml: 1 }} variant="subtitle2" target="_blank" href="https://youtu.be/Ic8FWyUL6r8?t=22">
                    Demo Video
                  </Link>
                </Typography>
              </Stack>
            </DialogContentText>
            <Stack direction="column" sx={{ mb: 3 }} spacing={3}>
              <TextField
                spellCheck="false"
                autoFocus
                margin="dense"
                id="apiToken"
                label="Toggl API Token"
                fullWidth
                variant="filled"
                value={tokenValue}
                onChange={(e) => {
                  setTokenValue(e.target.value);
                }}
              />
              <FormControl variant="filled">
                <InputLabel id="business-select-label">FreshBooks Business</InputLabel>
                <Select
                  labelId="business-select-label"
                  id="business-select"
                  value={businessID}
                  onChange={handleBusinessChange}
                >
                  {fbBusinesses &&
                    fbBusinesses.map((business, index) => (
                      <MenuItem value={business.id} key={index}>
                        <Stack direction="column">
                          <Typography variant="body">{business.name}</Typography>
                          <Typography variant="caption">{business.role}</Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Alert severity="info">One Tracking sync up to 90 days ({oldestSinceDate}) of newly Toggl records</Alert>

              {/* <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked checked={syncToOneMode} onChange={handleSyncToOne} />}
                  label="Sync all records to one project"
                />
              </FormGroup>

              {!syncToOneMode || (
                <>
                  <Autocomplete
                    disableClearable
                    id="project-select"
                    options={fbProjects}
                    value={_.find(fbProjects, (project) => project.id === projectID)}
                    getOptionLabel={(option) => option.title}
                    onChange={handleProjectChange}
                    renderInput={(params) => <TextField variant="filled" {...params} label="Freshbooks Project" />}
                  />
                </>
              )} */}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button variant="contained" onClick={handleDialogConfirm}>
              {!togglAPIToken ? 'Save' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackBarOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ bottom: { xs: 66 } }}
          autoHideDuration={4000}
          message={snackBarMsg}
          onClose={handleSnackBarClose}
          action={
            <>
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleSnackBarClose}>
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </>
          }
        />
      </Container>
    </Page>
  );
}
