import PropTypes from 'prop-types';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Emoji.propTypes = {
  symbol: PropTypes.string,
  label: PropTypes.string,
  sx: PropTypes.object,
};

export default function Emoji({ label, symbol, sx }) {
  <span sx={{ ...sx }} className="emoji" role="img" aria-label={label || ''} aria-hidden={label ? 'false' : 'true'}>
    {symbol}
  </span>;
}
