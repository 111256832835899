import PropTypes from 'prop-types';
import { Card, Stack, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import './invoma_assets/css/invoma_cash_receipt.scoped.css';
import _ from 'lodash';
import PreviewSwitcher from '../invoice/PreviewSwitcher';
import logoWhite from './invoma_assets/img/logo_white.svg';

import {
  convertLineBreaker,
  getCurrencyFormater,
  getInvomaClientInfoHtml,
  getInvomaLineItemTableHtml,
  getInvomaNotesHtml,
  getInvomaOwnerInfoHtml,
  getInvomaReceiptLineItemTableHtml,
  getInvomaSimpleReceiptLineItemTableHtml,
  getInvomaTotalHtml,
  getSubTotal,
  getTaxIndex,
  imgUrlToData,
} from './invoiceUtil';
// ----------------------------------------------------------------------

CashReceiptTemp.propTypes = {
  userInvoice: PropTypes.object,
  templateRef: PropTypes.object,
  isPreview: PropTypes.bool,
};

export default function CashReceiptTemp({ userInvoice, templateRef, isPreview }) {
  const invoice = isPreview ? userInvoice : sampleInvoice;
  console.log('Invoice Obj', invoice);
  const taxIndex = getTaxIndex(invoice);

  const [logoData, setLogoData] = useState(null);

  imgUrlToData(invoice.presentation.logo, (myBase64) => {
    setLogoData(myBase64);
  });
  return (
    <div className="tm_container">
      <Card className="tm_pos_invoice_wrap" id="tm_download_section" ref={templateRef}>
        <div className="tm_pos_invoice_top">
          <div className="tm_pos_company_name">{invoice.ownerInfo.name}</div>
          <div className="tm_pos_company_address">
            {`${invoice.ownerInfo.street} ${invoice.ownerInfo.street2 || ''} 
            ${invoice.ownerInfo.country} ${invoice.ownerInfo.code || ''}`}
          </div>
        </div>
        <div className="tm_pos_invoice_body">
          <div className="tm_invoice_seperator" />
          <div className="tm_invoice_seperator" />
          <ul className="tm_list">
            <li>
              <div className="tm_list_title">Date:</div>
              <div className="tm_list_desc">{invoice.issueDate}</div>
            </li>
            <li className="text-right">
              <div className="tm_list_title">Invoice No:</div>
              <div className="tm_list_desc">{invoice.invoiceNumber}</div>
            </li>
            <li>
              <div className="tm_list_title">Customer:</div>
              <div className="tm_list_desc">{`${invoice.clientContacts[0]?.fname} ${invoice.clientContacts[0]?.lname}`}</div>
            </li>
            <li className="text-right">
              <div className="tm_list_title">Company:</div>
              <div className="tm_list_desc">{invoice.clientInfo.orgName}</div>
            </li>
          </ul>
          <div className="tm_invoice_seperator" />
          <div className="tm_invoice_seperator" />
          <table className="tm_pos_invoice_table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Total</th>
              </tr>
            </thead>
            {getInvomaSimpleReceiptLineItemTableHtml(invoice)}
          </table>
          <div className="tm_invoice_seperator" />
          <div className="tm_invoice_seperator" />
          <div className="tm_bill_list">
            <div className="tm_bill_list_in">
              <div className="tm_bill_title">Sub-Total :</div>
              <div className="tm_bill_value">{getSubTotal(invoice)}</div>
            </div>
            {invoice.discountInfo && invoice.discountInfo.discountPercentage !== 0 && (
              <div className="tm_bill_list_in">
                <div className="tm_bill_title">{`Discount (${invoice.discountInfo.discountPercentage}%) :`}</div>
                <div className="tm_bill_value">
                  {getCurrencyFormater(invoice).format(invoice.discountInfo.discountTotal)}
                </div>
              </div>
            )}
            {Object.entries(taxIndex).map((tax, index) => (
              <div key={index} className="tm_bill_list_in">
                <div className="tm_bill_title">
                  {tax[0]} {`(${tax[1].rate}%) :`}
                </div>
                <div className="tm_bill_value">{`+${getCurrencyFormater(invoice).format(tax[1].total)}`}</div>
              </div>
            ))}
            <div className="tm_bill_list_in">
              <div className="tm_bill_title tm_bill_focus">Grand Total:</div>
              <div className="tm_bill_value tm_bill_focus">
                {getCurrencyFormater(invoice).format(invoice.totalAmount)}
              </div>
            </div>
          </div>
          <div className="tm_invoice_seperator" />
          <div className="tm_invoice_seperator" />
          <div className="tm_bill_list">
            <div className="tm_bill_list_in">
              <div className="tm_bill_title">Paid:</div>
              <div className="tm_bill_value">{`-${getCurrencyFormater(invoice).format(
                invoice.paymentInfo.paid.amount
              )}`}</div>
            </div>
            <div className="tm_bill_list_in">
              <div className="tm_bill_title">Due:</div>
              <div className="tm_bill_value">{`${getCurrencyFormater(invoice).format(
                invoice.paymentInfo.outstanding.amount
              )}`}</div>
            </div>
          </div>
          <div className="tm_invoice_seperator" />
          <div className="tm_invoice_seperator" />
          <div className="tm_pos_sample_text">
            <p>Thank You</p>
          </div>
        </div>
      </Card>
    </div>
  );
}

const sampleInvoice = {
  id: 377322,
  invoiceNumber: '#LL93784',
  clientName: 'Lowell H. Dominguez',
  description: '',
  issueDate: '2023-04-28',
  dueDate: '2023-05-28',
  totalAmount: '1919',
  fbStatus: 'draft',
  fbPaymentStatus: 'unpaid',
  currency: 'CAD',
  lineItems: [
    {
      amount: {
        amount: '350.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Six web page designs and three times revision	.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 9,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Website Design',
      qty: '1',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 1,
      taxAmount1: '8',
      taxAmount2: '0',
      taxName1: 'GST',
      taxName2: null,
      taxNumber1: null,
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '350.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '600.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Convert pixel-perfect frontend and make it dynamic.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 10,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Web Development',
      qty: '1',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 2,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '600.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '400.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: '"Android & Ios Application Development."',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 11,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'App Development',
      qty: '2',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 3,
      taxAmount1: '13',
      taxAmount2: '0',
      taxName1: 'HST',
      taxName2: null,
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '200.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '300.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Facebook, Youtube and Google Marketing.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 12,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Digital Marketing',
      qty: '3',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 4,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '100.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
  ],
  ownerInfo: {
    bus_phone: '6479666000',
    city: 'Toronto',
    code: 'K0B1G5',
    country: 'Canada',
    currency_code: 'CAD',
    date: '2021-03-18 16:23:38',
    fax: '',
    info_email: 'owner.demo@gmail.com',
    mob_phone: '6479666570',
    modern_system: true,
    name: 'Gelatech',
    province: 'Ontario',
    street: '86 Paul Street',
    street2: null,
    subdomain: '',
    vat_name: null,
    vat_number: null,
    vis_state: 0,
  },
  ownerContact: {
    email: 'owner@gmail.com',
    fname: 'James',
    lname: 'Chen',
    organization: '',
    userid: 1,
  },
  clientInfo: {
    street: '84 Spilman Street',
    street2: '',
    orgName: 'Victor SaaS',
    province: 'Ontario',
    country: 'Canada',
  },
  clientContacts: [
    {
      contactid: -1,
      email: 'client.demo@gmail.com',
      fname: 'Rose',
      invoiceid: 377322,
      lname: 'Luke',
      userid: 278320,
    },
  ],
  terms: 'All claims relating to quantity or shipping errors.\nDelivery dates are not guaranteed and Seller.',
  notes: 'PayPal: client.demo@gmail.com\nAccount Number: 982182',
  presentation: {
    logo: 'https://api.freshbooks.com/uploads/images/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50Ijo2NDM3MTEzLCJvcmlnaW5hbF9maWxlbmFtZSI6Imljb24tZGFyay5wbmciLCJsZW5ndGgiOjUyOTI0LCJmaWxlbmFtZSI6InVwbG9hZC1jZjUwMzcxOWUzNzhkNjIwZjUwNGIwZGFkMDIzNjcxOWY3ZmVjMTI1IiwiYnVja2V0IjoidXBsb2FkcyIsImtleSI6Iidkb2NzLSctNjQzNzExMy91cGxvYWQtY2Y1MDM3MTllMzc4ZDYyMGY1MDRiMGRhZDAyMzY3MTlmN2ZlYzEyNSIsInV1aWQiOiIyMzMxODIyZS0yNjA5LTQyZmItYTNkYy1iZjQ1YmQ3OGE3Y2YifQ.IFiS4ROkwibdxyoiGwSMZ7O2wzsBJnMd5r-VGqUGk00',
    dateFormat: 'mm/dd/yyyy',
  },
  paymentInfo: {
    paid: { amount: '100', code: 'CAD' },
    outstanding: { amount: '1819', code: 'CAD' },
  },
};
