import PropTypes from 'prop-types';
// material
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import moment from 'moment';
import SyncStatusLabel from './SyncStatusLabel';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

ProjectSyncMatch.propTypes = {
  projects: PropTypes.array,
  fbProjects: PropTypes.array,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  autoSyncMatch: PropTypes.object,
  expanded: PropTypes.bool,
};

export default function ProjectSyncMatch({
  projects,
  fbProjects,
  autoSyncMatch,
  onSave,
  onDelete,
  expanded = false,
  sx,
}) {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [isDeleting, setIsDeleting] = useState(false);
  const [sourceProjectError, setSourceProjectError] = useState(false);
  const [fbProjectError, setFBProjectError] = useState(false);

  if (!autoSyncMatch) {
    autoSyncMatch = { status: 'draft' };
  }

  const sourceProject = _.find(projects, (project) => project.id === autoSyncMatch.source_project_id);
  const [mySelectedSourceProject, setMySelectedSourceProject] = useState(sourceProject);
  const fbProject = _.find(fbProjects, (project) => project.id === autoSyncMatch.fb_project_id);
  const [mySelectedFBProject, setMySelectedFBProject] = useState(fbProject);
  const fbService = _.find(mySelectedFBProject?.services, (service) => service.id === autoSyncMatch.fb_service_id);
  const [mySelectedFBService, setMySelectedFBService] = useState(fbService);
  const [myStatus, setMyStatus] = useState(autoSyncMatch.status);

  const handleSave = () => {
    console.log('handleSave mySelectedSourceProject', mySelectedSourceProject);
    console.log('handleSave mySelectedFBProject', mySelectedFBProject);
    setSourceProjectError(!mySelectedSourceProject);
    setFBProjectError(!mySelectedFBProject);
    if (!mySelectedFBProject || !mySelectedSourceProject) return;

    // when save, turn status from draft into active
    if (myStatus === 'draft') {
      setMyStatus('active');
      autoSyncMatch.status = 'active';
      autoSyncMatch.created_date = moment().unix();
    }
    const newAutoSyncMatch = {
      [mySelectedSourceProject.id]: {
        ...autoSyncMatch,
        source_project_id: mySelectedSourceProject.id,
        source_workspace_id: mySelectedSourceProject.workspace_id,
        fb_project_id: mySelectedFBProject.id,
        fb_client_id: mySelectedFBProject.client_id,
        fb_service_id: mySelectedFBService ? mySelectedFBService.id : null,
      },
    };
    console.log('newAutoSyncMatch', newAutoSyncMatch);
    setIsExpanded(false);
    onSave(newAutoSyncMatch);
  };

  const handleDelete = () => {
    onDelete(autoSyncMatch);
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => {
        setIsExpanded(!isExpanded);
      }}
      sx={{
        bgcolor: 'background.neutral',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        ...sx,
      }}
    >
      <AccordionSummary
        expandIcon={<Iconify icon="material-symbols:expand-more" width={24} height={24} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Tooltip title={_.capitalize(myStatus)}>
          <Typography sx={{ px: 1 }}>{(myStatus === 'active' && '🟢') || '⚪'}</Typography>
        </Tooltip>
        <Typography>{mySelectedSourceProject?.name || '***'} (Toggl)</Typography>
        {/* <Typography sx={{ px: 1 }}>{'→'}</Typography> */}
        <Typography sx={{ px: 1 }}>{'⚡'}</Typography>
        <Typography>{mySelectedFBProject?.title || '***'} (FreshBooks)</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3.2}>
            <Autocomplete
              fullWidth
              disableClearable
              disabled={autoSyncMatch.status === 'active'}
              id="project-select"
              options={projects}
              value={mySelectedSourceProject}
              getOptionLabel={(option) => (option ? option.name : '')}
              onChange={(e, newProject) => {
                setMySelectedSourceProject(newProject);
              }}
              renderInput={(params) => (
                <TextField
                  error={sourceProjectError}
                  variant="filled"
                  {...params}
                  label="Toggl Project"
                  helperText={autoSyncMatch.status === 'active' && 'Cannot change when active'}
                />
              )}
            />
          </Grid>
          <Grid item xs={0} sm={0} md={1} display="flex" alignItems="center">
            <Iconify icon="material-symbols:arrow-right-alt-rounded" sx={{ width: 72, height: 48 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={3.2}>
            <Autocomplete
              fullWidth
              disableClearable
              id="project-select"
              options={fbProjects}
              value={mySelectedFBProject}
              getOptionLabel={(option) => option.title}
              onChange={(e, newProject) => {
                if (newProject.id !== mySelectedFBProject?.id) setMySelectedFBService(null);
                setMySelectedFBProject(newProject);
                setMySelectedFBService(null);
              }}
              renderInput={(params) => (
                <TextField error={fbProjectError} variant="filled" {...params} label="Freshbooks Project" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3.2}>
            <Autocomplete
              fullWidth
              disableClearable
              id="service-select"
              options={mySelectedFBProject?.services}
              value={mySelectedFBService}
              getOptionLabel={(option) => option.name}
              onChange={(e, newService) => {
                if (newService.id !== mySelectedFBService?.id) setMySelectedFBService(null);
                setMySelectedFBService(newService);
              }}
              renderInput={(params) => <TextField variant="filled" {...params} label="Freshbooks Service" />}
            />
            {/* <FormControl variant="filled" fullWidth>
              <InputLabel id="service-select-label">Service (Optional)</InputLabel>
              <Select
                labelId="service-select-label"
                id="service-select"
                value={mySelectedFBService || ''}
                onChange={(e) => {
                  console.log('newService', e.target.value);
                  setMySelectedFBService(e.target.value);
                }}
              >
                {mySelectedFBProject && mySelectedFBProject.services.length !== 0 ? (
                  mySelectedFBProject.services.map((service, index) => (
                    <MenuItem value={service} key={index}>
                      {service.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={''}>
                    <em>No Service</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl> */}
          </Grid>
          <Grid item xs={12} sm={6} md={1} display="flex" alignItems="center">
            {isDeleting ? (
              <ButtonGroup variant="outlined" aria-label="match actions">
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label="cancel"
                    color="info"
                    onClick={() => {
                      setIsDeleting(false);
                    }}
                  >
                    <Iconify icon="material-symbols:cancel-outline" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Confirm Delete">
                  <IconButton aria-label="confirm" color="error" onClick={handleDelete}>
                    <Iconify icon="line-md:circle-to-confirm-circle-transition" />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            ) : (
              <ButtonGroup variant="outlined" aria-label="match actions">
                <Tooltip title="Save">
                  <IconButton aria-label="save" color="primary" onClick={handleSave}>
                    <Iconify icon="material-symbols:save" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    <Iconify icon="material-symbols:delete-forever-outline" />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
