import PropTypes from 'prop-types';
import { Card, Stack } from '@mui/material';
// import styles from './invoma_assets/css/invoma.module.css';
import './invoma_assets/css/invoma.scoped.css';
import moment from 'moment';
import _ from 'lodash';
import { useState } from 'react';
import logoWhite from './invoma_assets/img/logo_white.svg';
import logo from './invoma_assets/img/logo.svg';
import sign from './invoma_assets/img/sign.svg';
import {
  getInvomaClientInfoHtml,
  getInvomaOwnerInfoHtml,
  getInvomaLineItemTableHtml,
  imgUrlToData,
  getInvomaTotalHtml,
  convertLineBreaker,
  getInvomaNotesHtml,
} from './invoiceUtil';
// ----------------------------------------------------------------------

GeneralDarkTemp.propTypes = {
  userInvoice: PropTypes.object,
  templateRef: PropTypes.object,
  isPreview: PropTypes.bool,
};

export default function GeneralDarkTemp({ userInvoice, templateRef, isPreview }) {
  const invoice = isPreview ? userInvoice : sampleInvoice;
  console.log('Invoice Obj', invoice);
  const [logoData, setLogoData] = useState(null);

  imgUrlToData(invoice.presentation.logo, (myBase64) => {
    setLogoData(myBase64);
  });

  return (
    <div className="tm_container">
      <Card>
        <body className="tm_invoice_wrap">
          <div className="tm_invoice tm_style1 tm_dark_invoice" id="tm_download_section" ref={templateRef}>
            <div className="tm_invoice_in">
              <div className="tm_invoice_head tm_align_center tm_mb20">
                <div className="tm_invoice_left">
                  <div className="tm_logo">
                    <img src={logoData || logoWhite} alt="Logo" width={50} height={50} />
                  </div>
                </div>
                <div className="tm_invoice_right tm_text_right">
                  <div className="tm_primary_color tm_f50 tm_text_uppercase">Invoice</div>
                </div>
              </div>
              <div className="tm_invoice_info tm_mb20">
                <div className="tm_invoice_seperator tm_gray_bg" />
                <div className="tm_invoice_info_list">
                  <p className="tm_invoice_number tm_m0">
                    Invoice No: <b className="tm_primary_color">{invoice.invoiceNumber}</b>
                  </p>
                  <p className="tm_invoice_date tm_m0">
                    Date: <b className="tm_primary_color">{invoice.issueDate}</b>
                  </p>
                </div>
              </div>
              <div className="tm_invoice_head tm_mb10">
                <div className="tm_invoice_left">
                  <p className="tm_mb2">
                    <b className="tm_primary_color">Invoice To:</b>
                  </p>
                  {getInvomaClientInfoHtml(invoice)}
                </div>
                <div className="tm_invoice_right tm_text_right">
                  <p className="tm_mb2">
                    <b className="tm_primary_color">Pay To:</b>
                  </p>
                  {getInvomaOwnerInfoHtml(invoice)}
                </div>
              </div>
              <div className="tm_table tm_style1 tm_mb30">
                <div className="tm_round_border">
                  <div className="tm_table_responsive">
                    <table>
                      <thead>
                        <tr>
                          <th className="tm_width_3 tm_semi_bold tm_primary_color tm_gray_bg">Item</th>
                          <th className="tm_width_4 tm_semi_bold tm_primary_color tm_gray_bg">Description</th>
                          <th className="tm_width_2 tm_semi_bold tm_primary_color tm_gray_bg">Price</th>
                          <th className="tm_width_1 tm_semi_bold tm_primary_color tm_gray_bg">Qty</th>
                          <th className="tm_width_2 tm_semi_bold tm_primary_color tm_gray_bg tm_text_right">Total</th>
                        </tr>
                      </thead>
                      {getInvomaLineItemTableHtml(invoice)}
                    </table>
                  </div>
                </div>
                <div className="tm_invoice_footer">
                  <div className="tm_left_footer">
                    <p className="tm_mb2">
                      <b className="tm_primary_color">Notes:</b>
                    </p>
                    {getInvomaNotesHtml(invoice)}
                  </div>
                  <div className="tm_right_footer">{getInvomaTotalHtml(invoice)}</div>
                </div>
              </div>
              <div className="tm_padd_15_20 tm_round_border">
                <p className="tm_mb5">
                  <b className="tm_primary_color">Terms & Conditions:</b>
                </p>
                <ul className="tm_m0 tm_note_list">{convertLineBreaker(invoice.terms)}</ul>
              </div>
            </div>
          </div>
        </body>
      </Card>
    </div>
  );
}

const sampleInvoice = {
  id: 377322,
  invoiceNumber: '#LL93784',
  clientName: 'Lowell H. Dominguez',
  description: '',
  issueDate: '2023-04-28',
  dueDate: '2023-05-28',
  totalAmount: '1919',
  fbStatus: 'draft',
  fbPaymentStatus: 'unpaid',
  currency: 'CAD',
  lineItems: [
    {
      amount: {
        amount: '350.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Six web page designs and three times revision	.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 9,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Website Design',
      qty: '1',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 1,
      taxAmount1: '8',
      taxAmount2: '0',
      taxName1: 'GST',
      taxName2: null,
      taxNumber1: null,
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '350.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '600.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Convert pixel-perfect frontend and make it dynamic.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 10,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Web Development',
      qty: '1',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 2,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '600.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '400.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: '"Android & Ios Application Development."',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 11,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'App Development',
      qty: '2',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 3,
      taxAmount1: '13',
      taxAmount2: '0',
      taxName1: 'HST',
      taxName2: null,
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '200.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '300.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Facebook, Youtube and Google Marketing.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 12,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Digital Marketing',
      qty: '3',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 4,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '100.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
  ],
  ownerInfo: {
    bus_phone: '6479666000',
    city: 'Toronto',
    code: 'K0B1G5',
    country: 'Canada',
    currency_code: 'CAD',
    date: '2021-03-18 16:23:38',
    fax: '',
    info_email: 'owner.demo@gmail.com',
    mob_phone: '6479666570',
    modern_system: true,
    name: 'Gelatech',
    province: 'Ontario',
    street: '86 Paul Street',
    street2: null,
    subdomain: '',
    vat_name: null,
    vat_number: null,
    vis_state: 0,
  },
  ownerContact: {
    email: 'owner@gmail.com',
    fname: 'James',
    lname: 'Chen',
    organization: '',
    userid: 1,
  },
  clientInfo: {
    street: '84 Spilman Street',
    street2: '',
    orgName: 'Victor SaaS',
    province: 'Ontario',
    country: 'Canada',
  },
  clientContacts: [
    {
      contactid: -1,
      email: 'client.demo@gmail.com',
      fname: 'Rose',
      invoiceid: 377322,
      lname: 'Luke',
      userid: 278320,
    },
  ],
  terms: 'All claims relating to quantity or shipping errors.\nDelivery dates are not guaranteed and Seller.',
  notes: 'PayPal: client.demo@gmail.com\nAccount Number: 982182',
  presentation: {
    logo: 'https://api.freshbooks.com/uploads/images/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50Ijo2NDM3MTEzLCJvcmlnaW5hbF9maWxlbmFtZSI6Imljb24tZGFyay5wbmciLCJsZW5ndGgiOjUyOTI0LCJmaWxlbmFtZSI6InVwbG9hZC1jZjUwMzcxOWUzNzhkNjIwZjUwNGIwZGFkMDIzNjcxOWY3ZmVjMTI1IiwiYnVja2V0IjoidXBsb2FkcyIsImtleSI6Iidkb2NzLSctNjQzNzExMy91cGxvYWQtY2Y1MDM3MTllMzc4ZDYyMGY1MDRiMGRhZDAyMzY3MTlmN2ZlYzEyNSIsInV1aWQiOiIyMzMxODIyZS0yNjA5LTQyZmItYTNkYy1iZjQ1YmQ3OGE3Y2YifQ.IFiS4ROkwibdxyoiGwSMZ7O2wzsBJnMd5r-VGqUGk00',
    dateFormat: 'mm/dd/yyyy',
  },
};
