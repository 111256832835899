import HourlyWorkTemp from './HourlyWorkTemp';
import GeneralTemp from './GeneralTemp';
import GeneralDarkTemp from './GeneralDarkTemp';
import PurchaseOrderTemp from './PurchaseOrderTemp';
import PackingSlipTemp from './PackingSlipTemp';
import GeneralReceiptTemp from './GeneralReceiptTemp';
import CashReceiptTemp from './CashReceiptTemp';

const templateComponentIndex = {
  general_temp_1: GeneralTemp,
  hourly_work_temp: HourlyWorkTemp,
  general_temp_dark: GeneralDarkTemp,
  purchase_order_temp: PurchaseOrderTemp,
  general_receipt_temp: GeneralReceiptTemp,
  cash_receipt_temp: CashReceiptTemp,
  packing_slip_temp: PackingSlipTemp,
};

export default templateComponentIndex;
