// @mui
import { Container, Stack } from '@mui/material';
// hooks
import { useEffect } from 'react';
import TogglSyncingLogo from '../components/TogglSyncingLogo';
import useAuth, { FBAppName } from '../hooks/useAuth';
import { envVariable } from '../utils/constant';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

export default function TogglSyncingLogin() {
  useEffect(() => {
    localStorage.setItem('currentApp', FBAppName.toggl_sycing);
  }, []);

  return (
    <Page title="Login to Toggl Syncing" sx={{ width: '100%' }}>
      <Container maxWidth="sm">
        <Stack
          sx={{ minHeight: '100vh' }}
          direction="column"
          justifyContent="center"
          justifyItems="center"
          spacing={10}
        >
          <TogglSyncingLogo disabledLink sx={{ width: 200, height: 200, marginX: 'auto' }} />
          <LoginForm
            fbAppSetting={{
              app_client_id: envVariable.toggl_fb_app_client_id,
              app_client_secret: envVariable.toggl_fb_app_client_secret,
              app_redirect_url: envVariable.toggl_fb_app_redirect_url,
              app_auth_page: envVariable.toggl_fb_app_auth_page,
            }}
          />
        </Stack>
      </Container>
    </Page>
  );
}
