import { useState } from 'react';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import TemplateGrid from '../sections/@dashboard/invoice/TemplateGrid';
import Page from '../components/Page';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import templates from '../_mock/templates';

// ----------------------------------------------------------------------

export default function InvoiceTemplates() {
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <Page title="Invoice Templates">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Templates
        </Typography>

        {/* {slide out filter and sort} */}
        {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              isOpenFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack> */}

        <TemplateGrid templates={templates} />
        {/* <ProductCartWidget /> */}
      </Container>
    </Page>
  );
}
