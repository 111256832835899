export const executeSequentially = async (funArray) => {
  console.log(funArray);
  // promiseArray.forEach((promiseEle) => {
  //   promiseEle.then();
  // });
  // promiseArray.forEach((promiseEle) => {
  //   await promiseEle;
  // });
  // Promise.all(promiseArray).then((result) => console.log(result));
  // eslint-disable-next-line no-restricted-syntax
  // for (const fn of funArray) {
  //   // eslint-disable-next-line no-await-in-loop
  //   await fn(); // call function to get returned Promise
  // }
};

export async function allSynchronously(resolvables, delayTime) {
  const results = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const resolvable of resolvables) {
    // eslint-disable-next-line no-await-in-loop
    await delay(delayTime);
    // eslint-disable-next-line no-await-in-loop
    results.push(await resolvable());
  }
  return results;
}

// export const delay = (ms = 1000) => new Promise((r) => setTimeout(r, ms));

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
