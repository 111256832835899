import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { sentenceCase } from 'change-case';
import Label from './Label';
import { statusCopyMap } from '../hooks/useSync';

// ----------------------------------------------------------------------

SyncStatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  isAutoSync: PropTypes.bool,
  sx: PropTypes.object,
};

export default function SyncStatusLabel({ status, isAutoSync, sx }) {
  return (
    <Label
      sx={{ ...sx }}
      variant="ghost"
      color={
        (status === statusCopyMap.error && 'error') ||
        (status === statusCopyMap.ready_to_sync && 'info') ||
        (status === statusCopyMap.syncing && 'info') ||
        'success'
      }
    >
      {status && (isAutoSync ? '⚡ ' : '') + sentenceCase(status)}
    </Label>
  );
}
