import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const templates = [
  {
    id: faker.datatype.uuid(),
    cover: `./static/mock-images/templates/temp_1.png`,
    name: 'Fitness Center Invoice',
    tempIndex: 'fitness_center',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
  {
    id: faker.datatype.uuid(),
    cover: `https://marketplace.canva.com/EAEuuBdnoPc/1/0/1131w/canva-pink-and-white-business-invoice-lTnbgps-9LY.jpg`,
    name: 'Pink and White Business Invoice',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
  {
    id: faker.datatype.uuid(),
    cover: `https://marketplace.canva.com/EAE97aSM4d0/2/0/309w/canva-black-and-white-modern-simple-leaves-invoice-Qo73yG1Pp2g.jpg`,
    name: 'Black and White Modern Simple Leaves Invoice',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
  {
    id: faker.datatype.uuid(),
    cover: `https://marketplace.canva.com/EAE-m8STDbA/3/0/566w/canva-modern-and-minimal-invoice-for-graphic-invoice-yMxcWW6MyQ0.jpg`,
    name: 'Modern and Minimal Invoice for Graphic Invoice',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
  {
    id: faker.datatype.uuid(),
    cover: `https://marketplace.canva.com/EAFb08dUUEA/1/0/1131w/canva-sales-invoice-document-in-beige-dark-green-simple-vibrant-minimalism-style-cd3VuOtXvTM.jpg`,
    name: 'Sales Invoice Document in Beige Dark Green Simple Vibrant Minimalism Style',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
  {
    id: faker.datatype.uuid(),
    cover: `https://marketplace.canva.com/EAFb1ONgoqM/1/0/1131w/canva-sales-invoice-document-in-orange-black-and-white-chic-photocentric-frames-style-qzPGhUwG_go.jpg`,
    name: 'Sales Invoice Document in Orange Black and White Chic Photocentric Frames Style',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
  {
    id: faker.datatype.uuid(),
    cover: `https://marketplace.canva.com/EAE_UgPhObM/1/0/1131w/canva-modern-contractor-invoice-%28printable%29-7CBqXpzoC2g.jpg`,
    name: 'Modern Contractor Invoice',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
  {
    id: faker.datatype.uuid(),
    cover: `https://marketplace.canva.com/EADaoeUNkj8/1/0/1131w/canva-white-and-blue-invoice-letterhead-qY2wI85daPo.jpg`,
    name: 'White and Blue Invoice Letterhead',
    views: faker.datatype.number({ min: 100, max: 9999, precision: 1 }),
    downloads: faker.datatype.number({ min: 100, max: 999, precision: 1 }),
  },
];

export default templates;
