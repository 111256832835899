import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import { Card, Divider, IconButton, ListItemButton, ListItemText, Typography , Box, Button, Grid, Paper, TextField, InputAdornment, Stack, Autocomplete } from '@mui/material';
import Iconify from '../Iconify';

const LineItemListCard = ({ selectedLineItems, availableLineItemList, onChange }) => {
  const [editItem, setEditItem] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const handleEdit = (item, index) => {
    setEditItem(item);
    setEditIndex(index);
    setOpenEdit(true);
    setIsNew(false);
  };

  const handleAdd = () => {
    setEditItem(null);
    setEditIndex(selectedLineItems.length);
    setIsNew(true);
    setOpenEdit(true);
  };

  const handleSave = (item) => {
    setOpenEdit(false);
    selectedLineItems[editIndex] = item;
    onChange(selectedLineItems);
  };

  const handleDelete = () => {
    setOpenEdit(false);
    selectedLineItems.splice(editIndex, 1);
    onChange(selectedLineItems);
  };

  return (
    <Card sx={{ maxWidth: 345, padding: "24px" }}>
        {openEdit ? (
            <LineItemEdit
                isNew={isNew}
                availableLineItemList={availableLineItemList}
                initialItem={editItem}
                onSave={handleSave}
                onCancel={() => setOpenEdit(false)}
                onDelete={handleDelete}
            />
        ) : (
          <>
            <LineItemListView lineItems={selectedLineItems} handleEdit={handleEdit} />
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" startIcon={<Iconify icon="ic:baseline-add" />} onClick={handleAdd}>
                Add New Line Item
              </Button>
            </Stack>
          </>
        )}
    </Card>
  )};

LineItemListCard.propTypes = {
  selectedLineItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  availableLineItemList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
};


// ####### Line Item List View #######
const LineItemListView = ({ lineItems, handleEdit }) => (
    <>
      <Typography gutterBottom variant="h6" component="div">
          Line Items
        </Typography>
        <List>
        {lineItems.map((item, index) => (
            <LineItem 
              key={index} 
              primary={item.name} 
              secondary={item.description}
              handleClick={() => handleEdit(item, index)}
            />
        ))}
        </List>
    </>
  );

const LineItemEdit = ({ initialItem, isNew, onSave, onCancel, onDelete, availableLineItemList }) => {
  const [lineItem, setLineItem] = useState(initialItem);
  const [selectedLineItem, setSelectedLineItem] = useState(null);


  const handleLineItemChange = (event, value) => {
    setSelectedLineItem(value);
    setLineItem(value);
    console.log('selectedLineItem', selectedLineItem);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "cost") {
      setLineItem(prev => ({ ...prev, unit_cost: { amount: value } }));
    } else {
      setLineItem(prev => ({ ...prev, [name]: value }));
    }
    // Calculate the amount, we need this to display
    setLineItem(prev => ({ ...prev, amount: { amount: prev.qty * prev.unit_cost.amount } }));
  };

  const handleSave = () => {
    onSave(lineItem);
  };

  const totalAmount = () => {
    let total = 0;
    if(lineItem && lineItem.qty && lineItem.unit_cost && lineItem.unit_cost.amount) {
      total = lineItem.qty * lineItem.unit_cost.amount;
    }
    return total.toFixed(2);
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {isNew ? "Add line item" : "Edit line item"}
      </Typography>
      <Stack direction={{ sm: 'row', lg: 'column' }} spacing={1}>
        {isNew ?
          <>
            <Autocomplete
              disableClearable
              id="vendor-select"
              fullWidth
              options={availableLineItemList}
              value={selectedLineItem}
              getOptionLabel={(option) => option.name}
              onChange={handleLineItemChange}
              renderInput={(params) => <TextField variant="filled" {...params} label="Select an existing line item" />}
            />
          </> :
          <TextField
          fullWidth
          label="Name"
          name="name"
          value={lineItem?.name || ''}
          onChange={handleChange}
          margin="normal"
          variant="filled"
        />}
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={lineItem?.description || ''}
          onChange={handleChange}
          margin="normal"
          variant="filled"
          multiline
        />
        <Grid container alignItems="center">
          <Grid xs={5}>
            <TextField
              fullWidth
              label="Quantity"
              name="qty"
              type="number"
              value={lineItem?.qty || ''}
              onChange={handleChange}
              variant="filled"
            />
          </Grid>
          <Grid xs={1}>
            <Typography variant="h6" align="center" fontWeight="bold">X</Typography>
          </Grid>
          <Grid xs={6}>
            <TextField
              fullWidth
              label="Price"
              name="cost"
              type="number"
              value={lineItem?.unit_cost?.amount || ''}
              onChange={handleChange}
              variant="filled"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" align="right" sx={{ mt: 2 }}>
          = $ {totalAmount()}
        </Typography>
        <Divider sx={{ mt: 1 }} />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          {!isNew && <Button
            variant="outlined"
            color="error"
            startIcon={<Iconify icon="eva:trash-2-outline" />}
            onClick={onDelete}
          >
            Delete
          </Button>}
          <Button
            variant="outlined"
            onClick={onCancel}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Iconify icon="eva:save-outline" />}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Stack>
      </>
  );
};

LineItemEdit.propTypes = {
  initialItem: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const LineItem = ({primary, secondary, handleClick}) => (
    <>
        <ListItemButton sx={{ paddingX: 1 }} onClick={handleClick}>
          <ListItemText 
              sx={{ marginBottom: "0 !important" }}
              primary={primary} 
              secondary={
              <Typography
                  variant="body2"
                  noWrap
                  sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  marginBottom: "0 !important",
                  }}
              >
                  {secondary}
              </Typography>
              }
          />
          <IconButton edge="end" aria-label="more">
              <Iconify icon="eva:edit-outline" width={20} height={20} />
          </IconButton>
        </ListItemButton>
        <Divider variant="inset" sx={{ marginLeft: 0 }}/>
    </>
  );

LineItem.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export { LineItemListCard, LineItem };