import _, { filter, isEmpty, map } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Menu,
  Link,
  IconButton,
  Alert,
  Paper,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  FilledInput,
  InputAdornment,
  Select,
  Snackbar,
  InputLabel,
  FormControl,
  MenuItem,
  Checkbox,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  Container,
  Typography,
  Grid,
  TableContainer,
  TablePagination,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { height } from '@mui/system';
import { shopifyApi } from '@shopify/shopify-api';
import { envVariable } from '../utils/constant';
import ShopifyLogo from '../components/ShopifyLogo';
import useAuth from '../hooks/useAuth';
import useSync, { statusCopyMap } from '../hooks/useSync';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import useQuery from '../hooks/useQuery';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'orderNumber', label: 'Order #', alignRight: false },
  { id: 'customer', label: 'Customer', alignRight: false },
  { id: 'totalAmount', label: 'Total Amount (Tax)', alignRight: false },
  { id: 'paymentStatus', label: 'Payment Status', alignRight: false },
  { id: 'createDate', label: 'Create Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'createDate') {
    if (moment(b[orderBy]).isBefore(a[orderBy])) return -1;
    if (moment(b[orderBy]).isAfter(a[orderBy])) return 1;
    if (moment(b[orderBy]).isSame(a[orderBy])) return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array || array.length === 0) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (record) => record.customer.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OtherIncome() {
  const {
    identity,
    isShopifyAuth,
    getShopifyToken,
    shopifyShop,
    user,
    saveTogglAPIToken,
    saveTogglUserID,
    getUser,
    saveTogglSyncToProjectID,
    getTogglSyncToProjectID,
    saveTogglSyncToClientID,
    saveTogglSyncedDate,
    saveFBBusinessID,
    saveTogglSyncToOneMode,
    getFBBusinessID,
    getTogglSyncedDate,
    fetchShopifyToken,
    getShopifySyncToClientID,
    saveShopifySyncToClientID,
  } = useAuth();

  const [togglAPIToken, setTogglAPIToken] = useState();
  const [shopifyAccessToken, setShopifyAccessToken] = useState();
  const [isShopifyAuthInProgress, setIsShopifyAuthInProgress] = useState(false);

  const [tokenValue, setTokenValue] = useState();
  const [shopName, setShopName] = useState(null);
  const [shopInputError, setShopInputError] = useState(null);
  const [shopifyOrderList, setShopifyOrderList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isReconnect, setIsReconnect] = useState(false);

  const {
    syncSelectedShopifyOrdersToFB,
    fetchShopifyOrders,
    fetchTimeTracking,
    fetchFBProjects,
    fetchFBClients,
    createFBProjectForToggl,
    createFBClientForToggl,
    deleteSelectedRecords,
  } = useSync();

  const [fbProjects, setFBProjects] = useState([]);
  const [projectID, setProjectID] = useState(0);
  const [syncToProject, setSyncToProject] = useState(null);

  const [fbClients, setFBClients] = useState([]);
  const [clientID, setClientID] = useState(0);

  const [fbBusinesses, setFBBusinesses] = useState([]);
  const [businessID, setBusinessID] = useState(0);
  const [accountBusinessID, setAccountBusinessID] = useState(0);

  const [syncedDate, setSyncedDate] = useState();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('createDate');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const [syncToOneMode, setSyncToOneMode] = useState(false);
  const oldestSinceDate = moment().subtract(3, 'months').add(2, 'days').format('YYYY-MM-DD');

  useEffect(() => {
    async function fetchUserObjFromDB() {
      const userObj = await getUser();
      console.log(userObj.data.toggl_apitoken);
      setShopifyAccessToken(userObj.data.shopify_access_token?.access_token);
      reloadShopifyOrderTable();
      setProjectID(userObj.data.toggl_syncto_projectid || 0);
      // setClientID(userObj.data.toggl_syncto_clientid || 0);

      const businessArray = identity.business_memberships?.map((business) => ({
        id: business.business.id,
        account_id: business.business.account_id,
        name: business.business.name,
        role: business.role,
        status: business.business.status,
      }));
      setFBBusinesses(businessArray);
      console.log('businessArray', businessArray);
      setBusinessID(userObj.data.fb_business_id || businessArray[0]?.id);
      console.log('businessID', userObj.data.fb_business_id);
      setAccountBusinessID(userObj.data.fb_account_business_id || businessArray[0]?.account_id);
    }
    if (businessID === 0) fetchUserObjFromDB();
  });

  useEffect(() => {
    async function fetchFBProjectsAndClients() {
      const userObj = await getUser();

      const freshbooksClients = await fetchFBClients(accountBusinessID);
      console.log('freshbooksClients', freshbooksClients);
      setFBClients(freshbooksClients);
      setClientID(userObj.data.shopify_syncto_clientid || freshbooksClients[0]?.id);
    }
    fetchFBProjectsAndClients();
  }, [businessID]);

  useEffect(() => {
    async function fetchShopifySetting() {
      // get shopify token and shop url from DB
      const accessToken = await getShopifyToken();
      console.log('fetchShopifyAuth accessToken', accessToken);
      console.log('fetchShopifyAuth isShopifyAuth', isShopifyAuth);
      if (!isShopifyAuth && !accessToken) {
        handleSettingDialogOpen();
      }

      console.log('fetchShopifyAuth shopifyShop', shopifyShop);
      if (shopifyShop) {
        console.log('shopifyShop', shopifyShop);
        setShopName(shopifyShop.substring(0, shopifyShop.indexOf('.')));
      }
      console.log('isShopifyAuth', isShopifyAuth);
    }
    fetchShopifySetting();
  }, [shopifyShop, isShopifyAuth]);

  useEffect(() => {
    async function fetchShopifyOrderList() {
      setIsLoading(true);
      const orderlist = await fetchShopifyOrders();
      setShopifyOrderList(orderlist);
      console.log('fetchShopifyOrderList convertedOrderList', orderlist);
    }

    if (isShopifyAuth) {
      fetchShopifyOrderList().then(() => setIsLoading(false));
    }
  }, [isShopifyAuth]);

  const query = useQuery();

  useEffect(() => {
    async function handleShopifyCallBack() {
      const shopifyAccessToken = await getShopifyToken();
      const shopURL = query.get('shop') ? query.get('shop') : localStorage.getItem('shopifyShopURL');
      console.log('shopify url', shopURL);
      console.log('shopify code', query.get('code'));

      // handle redirect from Shopify
      if (shopURL) {
        // get the shop name
        const shop = shopURL.substring(0, shopURL.indexOf('.'));
        setShopName(shop);

        // if redirect contain code, means it's oAuth callback
        if (query.get('code')) {
          setIsShopifyAuthInProgress(true);
          fetchShopifyToken().then(() => {
            setIsShopifyAuthInProgress(false);
          });
          setShopInputError(null);
          handleSettingDialogOpen();
        } else if (!shopifyAccessToken) {
          // if not code and shopify is not auth, then redirect user directly to permission page
          handleShopifyAuth(shop);
        }
      }
    }
    handleShopifyCallBack();
  }, []);

  useEffect(() => {
    console.log('businessID', businessID);
    console.log('fbBusinesses', fbBusinesses);
  });

  const getStatusNum = (ttlist, status) => {
    if (!ttlist || ttlist.length === 0) return 0;
    let totalNum = 0;
    ttlist.forEach((tt) => {
      if (tt.status === status) totalNum += 1;
    });
    return totalNum;
  };

  const handleDatePickerChange = (newValue) => {
    console.log(newValue);
    setSyncedDate(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSyncToOne = (event) => {
    setSyncToOneMode(event.target.checked);
  };

  const handleClientChange = (event, newValue) => {
    console.log(newValue);
    setClientID(newValue.id);
  };

  const handleBusinessChange = (event) => {
    const businesIDToFind = event.target.value;
    const foundBusinessObj = _.find(fbBusinesses, (businessObj) => businessObj.id === businesIDToFind);
    setBusinessID(foundBusinessObj.id);
    setAccountBusinessID(foundBusinessObj.account_id);
  };

  // const handleClientChange = (event) => {
  //   setClientID(event.target.value);
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(filteredRecords);
      return;
    }
    setSelected([]);
  };

  const handleSyncAll = (event) => {
    syncSelectedShopifyOrdersToFB(shopifyOrderList).then(() => {
      showSnackMsg(`All records sync successfully!`);
      setSelected([]);
    });
  };

  const handleSyncSelected = (event) => {
    if (syncToOneMode)
      syncSelectedShopifyOrdersToFB(selected).then(() => {
        showSnackMsg(`${selected.length} record(s) sync success!`);
        setSelected([]);
      });
    else {
      setSyncDialogOpen(true);
    }
  };

  const handleDeleteSelected = (event) => {
    deleteSelectedRecords(selected).then(() => {
      setSelected([]);
      reloadShopifyOrderTable();
    });
    setDeleteDialogOpen(false);
  };

  const handleRowSelect = (record) => {
    console.log('Selected record', record);
    const isSelected = selected.indexOf(record) === -1;
    let newSelected = _.clone(selected);
    if (isSelected) newSelected.push(record);
    else {
      newSelected = _.reject(newSelected, (tt) => tt.id === record.id);
    }
    setSelected(newSelected);
  };

  const isAllNewRecord = (selectedRecord) =>
    _.every(
      selectedRecord,
      (record) => record.status === statusCopyMap.ready_to_sync || record.status === statusCopyMap.error
    );

  const isAllSyncedRecord = (selectedRecord) =>
    _.every(selectedRecord, (record) => record.status === statusCopyMap.synced);

  const reloadShopifyOrderTable = () => {
    setIsLoading(true);
    fetchShopifyOrders().then(() => {
      setIsLoading(false);
      showSnackMsg('Refresh success!');
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [syncDialogOpen, setSyncDialogOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingDialogOpen = (event) => {
    console.log(togglAPIToken);
    if (tokenValue !== togglAPIToken) setTokenValue(togglAPIToken);
    setDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    setIsReconnect(false);
    setDialogOpen(false);
  };

  const handleDeleteDialogClose = (event) => {
    setDeleteDialogOpen(false);
  };

  const handleRefresh = (event) => {
    reloadShopifyOrderTable();
  };

  const handleSettingDialogConfirm = useCallback(async () => {
    setIsReconnect(false);
    if (syncToOneMode) {
      if (clientID === 0) {
        return;
      }
      saveShopifySyncToClientID(clientID);
    }

    saveFBBusinessID(businessID, accountBusinessID);
    // saveTogglSyncedDate(syncedDate.toString());
    saveTogglSyncToOneMode(syncToOneMode);

    // fetch again after close the dialog
    reloadShopifyOrderTable();
    setDialogOpen(false);
  });

  const handleSyncDialogConfirm = useCallback(async () => {
    // await saveTogglSyncToProjectID(projectID);
    await saveShopifySyncToClientID(clientID);

    syncSelectedShopifyOrdersToFB(selected).then(() => {
      showSnackMsg(`${selected.length} record(s) sync success!`);
      setSelected([]);
    });

    setSyncDialogOpen(false);
  });

  const handleShopifyAuth = useCallback(async (shop) => {
    console.log('shopify auth');
    console.log('input shop', shop);
    if (!!shop && shop.length !== 0) {
      // Step 1: get shop url, TODO need to add user input
      const shopUrl = `https://${shop}.myshopify.com`;
      const authURL = shopUrl + envVariable.shopify_auth_page;

      // Step 2: nav to shop auth page
      window.location.replace(authURL);
    } else {
      setShopInputError('Please enter your shop url');
    }
  });

  const showSnackMsg = (msg) => {
    setSnackBarOpen(true);
    setSnackBarMsg(msg);
  };
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - shopifyOrderList.length) : 0;

  const filteredRecords = applySortFilter(shopifyOrderList, getComparator(order, orderBy), filterName);

  const isRecordNotFound = filterName !== '' && filteredRecords.length === 0;

  return (
    <Page title="Shopify as Other Income">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Stack direction="column" alignItems="left">
            <Typography variant="h4" gutterBottom>
              Hi {identity?.first_name}, Welcome back
            </Typography>
            <Typography variant="subtitle" gutterBottom>
              Syncing Shopify order as other income
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
            <Button
              variant="outlined"
              onClick={handleSettingDialogOpen}
              startIcon={
                <Avatar
                  variant="square"
                  alt="Shoipfy icon"
                  src="./static/icons/ic_shopify.png"
                  sx={{ height: 24, width: 24, objectFit: 'contain' }}
                />
              }
            >
              Shopify Settings
            </Button>
            {syncToOneMode && (
              <Button variant="contained" startIcon={<Iconify icon="eva:sync-fill" />} onClick={handleSyncAll}>
                Sync All
              </Button>
            )}
          </Stack>
        </Stack>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Ready to Sync"
              total={getStatusNum(shopifyOrderList, statusCopyMap.ready_to_sync)}
              color="info"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Synced to FreshBooks"
              total={getStatusNum(shopifyOrderList, statusCopyMap.synced)}
              color="success"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Sync Error"
              total={getStatusNum(shopifyOrderList, statusCopyMap.error)}
              color="error"
            />
          </Grid>
        </Grid>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterPlaceholder={'Search Customer Name...'}
            onRefresh={handleRefresh}
            onSyncSelected={isAllNewRecord(selected) ? handleSyncSelected : null}
            onDeleteSelected={
              isAllSyncedRecord(selected)
                ? () => {
                    setDeleteDialogOpen(true);
                  }
                : null
            }
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredRecords.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {!isLoading &&
                    filteredRecords.length !== 0 &&
                    filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => {
                      const {
                        id,
                        orderNumber,
                        customer,
                        status,
                        totalAmount,
                        totalTax,
                        paymentStatus,
                        currency,
                        createDate,
                      } = record;
                      const currencyFormatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency,
                      });

                      const displayTax = currencyFormatter
                        .formatToParts(totalTax)
                        .filter((x) => x.type !== 'currency')
                        .map((x) => x.value)
                        .join('')
                        .trim();
                      const displayTotalAmount = `${currencyFormatter.format(totalAmount)} (${displayTax})`;
                      const isItemSelected = selected.indexOf(record) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={() => handleRowSelect(record)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onChange={() => handleRowSelect(record)} />
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ maxWidth: 250 }}>
                            <Typography variant="subtitle2" noWrap>
                              {orderNumber || '<Not Available>'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ maxWidth: 500 }}>
                            {customer || '<Not Available>'}
                          </TableCell>
                          <TableCell align="left">{displayTotalAmount || '<Not Available>'}</TableCell>
                          <TableCell align="left">{paymentStatus || '<Not Available>'}</TableCell>
                          <TableCell align="left" sx={{ minWidth: 128 }}>
                            {moment(createDate).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell align="right">
                            <Label
                              variant="ghost"
                              color={
                                (status === statusCopyMap.error && 'error') ||
                                (status === statusCopyMap.ready_to_sync && 'info') ||
                                (status === statusCopyMap.syncing && 'info') ||
                                'success'
                              }
                            >
                              {status && sentenceCase(status)}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && !isLoading && (
                    <TableRow style={{ height: 56 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isShopifyAuth && isRecordNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {!isLoading && shopifyOrderList?.length === 0 && !isShopifyAuthInProgress && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            {!isShopifyAuth
                              ? "You haven't setup the Shopify yet!"
                              : 'No Records? Try to update the Shopify connection'}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={handleSettingDialogOpen}
                            startIcon={
                              <Avatar
                                variant="square"
                                alt="Shoipfy icon"
                                src="./static/icons/ic_shopify.png"
                                sx={{ height: 24, width: 24, objectFit: 'contain' }}
                              />
                            }
                          >
                            {!isShopifyAuth ? 'Connect to Shopify ' : 'Update Shopify Connection'}
                          </Button>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1.5,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleSettingDialogOpen}>
              <Avatar alt="Toggl icon" src="./static/icons/toggl.png" /> Toggl
            </MenuItem>
            <MenuItem>
              <Avatar alt="Harvest icon" src="./static/icons/harvest.png" /> Harvest
            </MenuItem>
          </Menu>
        </Card>

        {/* Sync dialog */}
        <Dialog
          open={syncDialogOpen}
          onClose={() => setSyncDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Sync ${selected.length} record(s)?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Select which FreshBooks client you want sync to
            </DialogContentText>
          </DialogContent>
          <Stack direction="column" sx={{ mb: 3, mx: 3 }} spacing={3}>
            <Autocomplete
              disableClearable
              id="client-select"
              options={fbClients}
              value={_.find(fbClients, (client) => client.id === clientID)}
              getOptionLabel={(option) => option.organization}
              onChange={handleClientChange}
              renderInput={(params) => <TextField variant="filled" {...params} label="Freshbooks Client (Optional)" />}
            />

            {/* <FormControl variant="filled">
              <InputLabel id="client-select-label">FreshBooks Client</InputLabel>
              <Select
                labelId="client-select-label"
                id="client-select"
                value={clientID || 0}
                onChange={handleClientChange}
              >
                {fbClients &&
                  fbClients.map((client, index) => (
                    <MenuItem value={client.id} key={index}>
                      {client.organization}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
          </Stack>
          <DialogActions>
            <Button onClick={() => setSyncDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSyncDialogConfirm}>
              Sync
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Delete ${selected.length} sync record(s)?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              When you delete, we will only remove the sync record on OneTracking. We won't delete record on FreshBooks
              and Shopify!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>Close</Button>
            <Button onClick={handleDeleteSelected} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Setting dialog */}
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{!isShopifyAuth ? 'Connect to Shopify' : 'Update Shopify Settings'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!isShopifyAuth
                ? 'To connect to Shopify, you need to input your shop url.'
                : 'Below is your current connected Shopify account and sync settings.'}
            </DialogContentText>
            <Stack direction="column" sx={{ mb: 3, mt: 3 }} spacing={2}>
              {isShopifyAuth && !isReconnect ? (
                <Alert
                  severity="success"
                  variant="filled"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsReconnect(true);
                      }}
                    >
                      Edit Connection
                    </Button>
                  }
                >
                  Success Connected to Shopify
                </Alert>
              ) : (
                <Stack direction="column" spacing={2}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor="filled-adornment-amount">Shopify Shop URL</InputLabel>
                    <FilledInput
                      error={!!shopInputError}
                      id="filled-adornment-amount"
                      value={shopName}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setShopName(e.target.value);
                        setShopInputError(null);
                      }}
                      startAdornment={<InputAdornment position="start">https://</InputAdornment>}
                      endAdornment={<InputAdornment position="start">.myshop.com</InputAdornment>}
                    />
                    {!shopInputError || (
                      <Typography variant="body2" color="error">
                        Please enter your shop url
                      </Typography>
                    )}
                  </FormControl>
                  {isShopifyAuthInProgress ? (
                    <CircularProgress sx={{ marginX: 'auto !important' }} size={48} />
                  ) : (
                    <LoadingButton
                      fullWidth
                      isLoading={isShopifyAuthInProgress}
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => handleShopifyAuth(shopName)}
                      sx={{ background: 'linear-gradient(180deg,#6371c7,#5563c1)' }}
                    >
                      <Stack spacing={4} direction="row" alignItems="center" justifyContent="center">
                        <ShopifyLogo sx={{ marginRight: 1 }} />
                        Connect to Shopify
                      </Stack>
                    </LoadingButton>
                  )}
                </Stack>
              )}

              <FormControl variant="filled">
                <InputLabel id="business-select-label">FreshBooks Business</InputLabel>
                <Select
                  labelId="business-select-label"
                  id="business-select"
                  value={businessID}
                  onChange={handleBusinessChange}
                >
                  {fbBusinesses &&
                    fbBusinesses.map((business, index) => (
                      <MenuItem value={business.id} key={index}>
                        <Stack direction="column">
                          <Typography variant="body">{business.name}</Typography>
                          <Typography variant="caption">{business.role}</Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSettingDialogConfirm}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackBarOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          message={snackBarMsg}
          onClose={handleSnackBarClose}
          sx={{ bottom: { xs: 66 } }}
          action={
            <>
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleSnackBarClose}>
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </>
          }
        />
      </Container>
    </Page>
  );
}
