import { Link as RouterLink } from 'react-router-dom';
// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, CardActionArea, Typography } from '@mui/material';
// utils
import { FBAppLoginURL } from '../../../routes';
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  // width: theme.spacing(8),
  // height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppBlock.propTypes = {
  appName: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  logo: PropTypes.element,
  sx: PropTypes.object,
};

export default function AppBlock({ shortDescription, appName, logo, sx, ...other }) {
  return (
    <Card
      sx={{
        boxShadow: 1,
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <CardActionArea sx={{ py: 5, px: 2 }}>
        <IconWrapperStyle>{logo}</IconWrapperStyle>

        <Typography variant="h5">{appName}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {shortDescription}
        </Typography>
      </CardActionArea>
    </Card>
  );
}
