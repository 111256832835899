import PropTypes from 'prop-types';
// material
import { Button, Paper, Typography } from '@mui/material';
import SyncStatusLabel from './SyncStatusLabel';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
  filterStatus: PropTypes.string,
  onClearFilters: PropTypes.func,
};

export default function SearchNotFound({ searchQuery = '', filterStatus, onClearFilters, ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        No Results Found
      </Typography>
      {searchQuery !== '' && (
        <Typography variant="body2" align="center">
          No results found for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos.
        </Typography>
      )}
      {filterStatus && (
        <Typography variant="body2" align="center" sx={{ mt: 1 }}>
          Table filtered by status:
          <SyncStatusLabel status={filterStatus} sx={{ mx: 1 }} />
        </Typography>
      )}
      {onClearFilters && (
        <Button variant="outlined" sx={{ mt: 2 }} onClick={onClearFilters}>
          Clear all filter
        </Button>
      )}
    </Paper>
  );
}
