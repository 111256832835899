import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

ShopifySyncingLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function ShopifySyncingLogo({ sx }) {
  // OR
  const logo = (
    <Box component="img" src="../static/icons/logo_shopify_syncing_app.png" sx={{ width: 120, height: 120, ...sx }} />
  );

  return <>{logo}</>;
}
