import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

TogglSyncingLogo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function TogglSyncingLogo({ sx }) {
  // OR
  const logo = (
    <Box component="img" src="../static/icons/logo_toggl_syncing_app.png" sx={{ width: 120, height: 120, ...sx }} />
  );

  return <>{logo}</>;
}
