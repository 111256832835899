// @mui
import { Container, Stack } from '@mui/material';
// hooks
import { useEffect } from 'react';
import useAuth, { FBAppName } from '../hooks/useAuth';
import { envVariable } from '../utils/constant';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
import FreshBooksSyncingShopifyAppLogo from '../components/FreshBooksSyncingShopifyAppLogo';
import useQuery from '../hooks/useQuery';

// ----------------------------------------------------------------------
// This is the login page for Shopify to use and serve as app URL

export default function FBSyncingShopifyAppLogin() {
  const query = useQuery();
  const { fetchShopifyToken } = useAuth();

  useEffect(() => {
    localStorage.setItem('currentApp', FBAppName.shopify_syncing);

    // need to handling app launched from shopify scenario
    // save shop url to localstorage
    if (query.get('shop')) {
      // when the path contain both shop and code means it's a scope request success callback
      if (query.get('code')) {
        // need to use the code and shop url to request shopify access token
        fetchShopifyToken();
        return;
      }
      localStorage.setItem('shopifyShopURL', query.get('shop'));
      const authURL = `https://${query.get('shop')}${envVariable.shopify_app_auth_page}`;
      console.log(authURL);
      window.location.replace(authURL);
    }
  }, []);

  return (
    <Page title="Login to Shopify Syncing" sx={{ width: '100%' }}>
      <Container maxWidth="sm">
        <Stack sx={{ minHeight: '100vh' }} direction="column" justifyContent="center" justifyItems="center" spacing={5}>
          <FreshBooksSyncingShopifyAppLogo disabledLink sx={{ width: '100vh', height: 'auto', marginX: 'auto' }} />
          <LoginForm
            pageTitle="Next Step: Connect to FreshBooks"
            fbAppSetting={{
              app_client_id: envVariable.shopify_fb_app_client_id,
              app_client_secret: envVariable.shopify_fb_app_client_secret,
              app_redirect_url: envVariable.shopify_fb_app_redirect_url,
              app_auth_page: envVariable.shopify_fb_app_auth_page,
            }}
          />
        </Stack>
      </Container>
    </Page>
  );
}
