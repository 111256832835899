const envStaging = {
  gelatech_support_email: 'hellogelatech@gmail.com',
  onetracking_backend_url: 'http://localhost:8080',
  invoice_email_template_id: 'z3m5jgr9z5zgdpyo',
  support_email_template_id: 'o65qngk2we8gwr12',
  onetracking_link_url_prefix: 'http://localhost:3000/link/',
  toggl_fb_app_client_id: '8ab97eb6164f95be2f1125bed014e80010872aafd379525c1d52c565fc450a62',
  toggl_fb_app_client_secret: '48fa805c4e9e85418ff341e95b820c683b28a57d03f3d68c4922715142c31a29',
  toggl_fb_app_redirect_url: 'https://onetracking.gelatech.com/toggl_syncing_login',
  toggl_fb_app_auth_page:
    'https://auth.freshbooks.com/oauth/authorize?client_id=3351e01979a74f2c2fbc2a1698855abbc8879a0d8e065a0b2393ced8aa1ccaab&response_type=code&redirect_uri=https%3A%2F%2Fonetracking.gelatech.com%2Ftoggl_syncing_login&scope=user%3Aprofile%3Aread%20user%3Atime_entries%3Aread%20user%3Atime_entries%3Awrite%20user%3Ainvoices%3Aread%20user%3Ainvoices%3Awrite%20user%3Aprojects%3Aread%20user%3Aprojects%3Awrite%20user%3Aclients%3Aread%20user%3Aclients%3Awrite%20user%3Aother_income%3Aread%20user%3Aother_income%3Awrite',
  shopify_fb_app_client_id: 'f76d389e33902bf58ed93ab6548fffabbf81e7d7f6b9551ce4c10b935483b206',
  shopify_fb_app_client_secret: '2e06146ed1ae98d7055436f9e65841bbc3226a5e08a309624784c7ac3c40f3a4',
  shopify_fb_app_redirect_url: 'https://onetracking.gelatech.com/shopify_syncing_login',
  shopify_fb_app_auth_page:
    'https://auth.freshbooks.com/oauth/authorize?client_id=f76d389e33902bf58ed93ab6548fffabbf81e7d7f6b9551ce4c10b935483b206&response_type=code&redirect_uri=https%3A%2F%2Fonetracking.gelatech.com%2Fshopify_syncing_login&scope=user%3Aprofile%3Aread%20user%3Aother_income%3Aread%20user%3Aother_income%3Awrite%20user%3Aclients%3Aread%20user%3Aclients%3Awrite%20user%3Atime_entries%3Aread%20user%3Atime_entries%3Awrite%20user%3Ainvoices%3Aread%20user%3Ainvoices%3Awrite%20user%3Aprojects%3Aread%20user%3Aprojects%3Awrite%20user%3Ajournal_entries%3Aread%20user%3Ajournal_entries%3Awrite',
  shopify_auth_page:
    '/admin/oauth/authorize?client_id=c0e66514bad3d6a7c46cfa0d0c782753&redirect_uri=https%3A%2F%2Fonetracking.gelatech.com%2Fdashboard%2Fotherincome&scope=read_all_orders%2Cread_orders%2Cwrite_orders',
  shopify_app_auth_page:
    '/admin/oauth/authorize?client_id=c0e66514bad3d6a7c46cfa0d0c782753&redirect_uri=https%3A%2F%2Fonetracking.gelatech.com%2Fshopify_syncing_login&scope=read_all_orders%2Cread_orders%2Cwrite_orders',
};

const frontEndURLEncoded = 'https%3A%2F%2Fonetracking.gelatech.com%2F';
// const frontEndURLEncoded = 'https%3A%2F%2Fonetracking.gelatech.com%2F';

const envProduction = {
  gelatech_support_email: 'hellogelatech@gmail.com',
  onetracking_backend_url: 'https://gelatech-onetracking-backend.uc.r.appspot.com',
  invoice_email_template_id: 'z3m5jgr9z5zgdpyo',
  support_email_template_id: 'o65qngk2we8gwr12',
  onetracking_link_url_prefix: 'https://onetracking.gelatech.com/link/',
  toggl_fb_app_client_id: '3351e01979a74f2c2fbc2a1698855abbc8879a0d8e065a0b2393ced8aa1ccaab',
  toggl_fb_app_client_secret: '859ba9162bb4b1c80d94c6aa43064b43f19377ce8ac0805f81dcef2278b3638e',
  toggl_fb_app_redirect_url: 'https://onetracking.gelatech.com/toggl_syncing_login',
  toggl_fb_app_auth_page: `https://auth.freshbooks.com/oauth/authorize?client_id=3351e01979a74f2c2fbc2a1698855abbc8879a0d8e065a0b2393ced8aa1ccaab&response_type=code&redirect_uri=${frontEndURLEncoded}toggl_syncing_login&scope=user%3Aprofile%3Aread%20user%3Atime_entries%3Aread%20user%3Atime_entries%3Awrite%20user%3Ainvoices%3Aread%20user%3Ainvoices%3Awrite%20user%3Aprojects%3Aread%20user%3Aprojects%3Awrite%20user%3Aclients%3Aread%20user%3Aclients%3Awrite%20user%3Aother_income%3Aread%20user%3Aother_income%3Awrite`,
  shopify_fb_app_client_id: 'f76d389e33902bf58ed93ab6548fffabbf81e7d7f6b9551ce4c10b935483b206',
  shopify_fb_app_client_secret: '2e06146ed1ae98d7055436f9e65841bbc3226a5e08a309624784c7ac3c40f3a4',
  shopify_fb_app_redirect_url: 'https://onetracking.gelatech.com/shopify_syncing_login',
  shopify_fb_app_auth_page: `https://auth.freshbooks.com/oauth/authorize?client_id=f76d389e33902bf58ed93ab6548fffabbf81e7d7f6b9551ce4c10b935483b206&response_type=code&redirect_uri=${frontEndURLEncoded}shopify_syncing_login&scope=user%3Aprofile%3Aread%20user%3Aother_income%3Aread%20user%3Aother_income%3Awrite%20user%3Aclients%3Aread%20user%3Aclients%3Awrite%20user%3Atime_entries%3Aread%20user%3Atime_entries%3Awrite%20user%3Ainvoices%3Aread%20user%3Ainvoices%3Awrite%20user%3Aprojects%3Aread%20user%3Aprojects%3Awrite%20user%3Ajournal_entries%3Aread%20user%3Ajournal_entries%3Awrite`,
  shopify_auth_page: `/admin/oauth/authorize?client_id=c0e66514bad3d6a7c46cfa0d0c782753&redirect_uri=${frontEndURLEncoded}dashboard%2Fotherincome&scope=read_all_orders%2Cread_orders%2Cwrite_orders`,
  fb_syncing_shopify_app_redirect_url: 'https://onetracking.gelatech.com/fbsyncing_shopify_login',
  shopify_app_auth_page: `/admin/oauth/authorize?client_id=c0e66514bad3d6a7c46cfa0d0c782753&redirect_uri=${frontEndURLEncoded}fbsyncing_shopify_login&scope=read_all_orders%2Cread_orders%2Cwrite_orders`,
  invoiceinstyle_fb_app_client_id: '3b9bec9bdc9a4461a5ea529b1206812e50073b76cca1f7bb5576823fcebb3e2d',
  invoiceinstyle_fb_app_client_secret: '0136ca0a08245e40fdc69011e9cef1484827cf7ad1c8f93ab10ea1e39aadeacb',
  invoiceinstyle_fb_app_redirect_url: 'https://onetracking.gelatech.com/invoice_in_style_login',
  invoiceinstyle_fb_app_auth_page: `https://auth.freshbooks.com/oauth/authorize?client_id=3b9bec9bdc9a4461a5ea529b1206812e50073b76cca1f7bb5576823fcebb3e2d&response_type=code&redirect_uri=https%3A%2F%2Fonetracking.gelatech.com%2Finvoice_in_style_login&scope=user%3Aprofile%3Aread%20user%3Ainvoices%3Aread%20user%3Ainvoices%3Awrite%20user%3Auploads%3Aread%20user%3Auploads%3Awrite%20user%3Aonline_payments%3Aread%20user%3Aonline_payments%3Awrite%20user%3Abillable_items%3Aread%20user%3Abillable_items%3Awrite%20user%3Abill_vendors%3Aread%20user%3Abill_vendors%3Awrite%20user%3Apayments%3Aread%20user%3Apayments%3Awrite`,
};

export const envVariable = envProduction;
