import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Button, Box, Card, Link, Typography, Stack, ButtonGroup, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import Iconify from '../../../components/Iconify';
import { fCurrency, fShortenNumber } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const TemplateImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  position: 'absolute',
});

const NumInfoStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(0),
  color: theme.palette.text.disabled,
}));

// ----------------------------------------------------------------------

TemplateCard.propTypes = {
  onClick: PropTypes.func,
  template: PropTypes.object,
};

export default function TemplateCard({ template, onClick }) {
  const { id, name, cover, views, downloads, send } = template;

  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <Box sx={{ pt: '100%', position: 'relative', overflow: 'hidden' }}>
          <TemplateImgStyle alt={name} src={cover} />
        </Box>

        <Stack spacing={1} sx={{ px: 2, py: 1 }} direction="column">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          <Stack direction="row" spacing={1}>
            <NumInfoStyle>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: 0,
                }}
              >
                <Iconify icon={'eva:eye-fill'} sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant="caption">{fShortenNumber(views)}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: 1.5,
                }}
              >
                <Iconify icon={'material-symbols:download-rounded'} sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant="caption">{fShortenNumber(downloads)}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: 1.5,
                }}
              >
                <Iconify icon={'mingcute:send-plane-line'} sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant="caption">{fShortenNumber(send)}</Typography>
              </Box>
            </NumInfoStyle>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  );
}
