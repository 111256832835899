// Import the functions you need from the SDKs you need
import * as FS from '@fullstory/browser';

const useFullstory = () => {
  if (!FS.isInitialized()) {
    // Initialize fullstory
    FS.init({ orgId: '16WH60' });
  }

  return { FS };
};

export default useFullstory;
