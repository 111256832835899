import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Stack, Grid } from '@mui/material';
import ShopifySyncingLogo from '../components/ShopifySyncingLogo';
import InvoiceInStyleLogo from '../components/InvoiceInStyleLogo';
import TogglSyncingLogo from '../components/TogglSyncingLogo';
import AppBlock from '../sections/@dashboard/app/AppBlock';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { RegisterForm } from '../sections/auth/register';
import AuthSocial from '../sections/auth/AuthSocial';
import { FBAppLoginURL } from '../routes';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  minWidth: 500,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Landing() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Landing">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10 }}>
              Welcome to OneTracking
            </Typography>
            <Typography variant="body" sx={{ px: 5, mb: 5 }}>
              Tracking everything at one place
            </Typography>
            <img alt="landing" src="./static/illustrations/illustration_register.png" />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              We have many apps to help you.
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Select one that applies to your workflow.</Typography>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6} md={6}>
                <RouterLink to={FBAppLoginURL.toggl_sycing} style={{ textDecoration: 'none' }}>
                  <AppBlock
                    appName="Toggl Syncing"
                    shortDescription="Syncing Toggl time tracking to FreshBooks"
                    logo={<TogglSyncingLogo />}
                  />
                </RouterLink>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <RouterLink to={FBAppLoginURL.shopify_syncing} style={{ textDecoration: 'none' }}>
                  <AppBlock
                    appName="Shopify Syncing"
                    shortDescription="Syncing Shopify orders to FreshBooks as other incomes"
                    logo={<ShopifySyncingLogo />}
                  />
                </RouterLink>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <RouterLink to={FBAppLoginURL.invoice_in_style} style={{ textDecoration: 'none' }}>
                  <AppBlock
                    appName="Invoice In Style"
                    shortDescription="Customize your invoices with stylish templates"
                    logo={<InvoiceInStyleLogo />}
                  />
                </RouterLink>
              </Grid>
            </Grid>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
